import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  HStack,
  VStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  Switch,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  Image,
  useBreakpointValue,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
} from "@chakra-ui/react";
import { AiOutlineFullscreen } from "react-icons/ai";
import {
  FiArrowUp,
  FiArrowRight,
  FiArrowDown,
  FiPlay,
  FiSkipBack,
  FiSkipForward,
  FiMoreHorizontal,
  FiMic,
  FiVolume2,
  FiCircle,
  FiHardDrive,
} from "react-icons/fi";
import { BiPlay, BiSkipPrevious, BiSkipNext } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getStreamDetails, setImageUrl } from "../actions/cameraActions";
import Player from "../components/Player";
import PlayerControls from "../components/PlayerControls";
import { InfoIcon } from "@chakra-ui/icons";
import axios from "axios";
import MobileHeader from "../components/MobileHeader";

const CameraView = () => {
  const [device, setDevice] = useState([]);
  const { deviceId } = useParams();
  const location = useLocation();
  const { status } = location.state || {}; // Safely access status
  const navigate = useNavigate();
  const tabActiveColor = useColorModeValue("#141E35", "#65758B");
  const tabInactiveColor = useColorModeValue("#BCB4DA", "#8E8D9F");
  const colorMode = useColorModeValue("light", "dark");

  // Function to fetch stream details

  const fetchStreamDetails = async (deviceId) => {
    try {
      const response = await getStreamDetails(deviceId);
      console.log("Get getStreamDetails", response);

      // Check if response contains streamData and set it to device state
      if (
        response.success &&
        response.streamData &&
        response.streamData.length > 0
      ) {
        setDevice(response.streamData[0]);
      } else {
        setDevice({});
      }
    } catch (error) {
      console.error("Error fetching cameras:", error);
    } finally {
      // setIsLoading(false); // Stop loading when data is fetched
    }
  };

  useEffect(() => {
    fetchStreamDetails(deviceId);
  }, [deviceId]);

  const width = useBreakpointValue({ base: "100%" });
  const getResponsivePlayerStyle = () => ({
    width,
    height: "auto",
    aspectRatio: "16 / 9",
  });

  const url =
    device?.plan === "LIVE"
      ? `https://${device.deviceId}.${device.p2purl}/flv/live_ch0_0.flv?verify=${device.token}`
      : `https://${device.mediaUrl}/hdl/${device.plan}/RTSP-${device.deviceId}.flv`;

  // const url =
  //   "https://media2.arcisai.io:8443/hdl/DVR-30/RTSP-VSPL-117465-POOJAN.flv";
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control hooks
  const [isToggled, setIsToggled] = React.useState(false); // Track switch state

  const handleToggle = () => {
    setIsToggled(!isToggled); // Toggle the switch state
    if (!isToggled) {
      onOpen(); // Open the modal when toggled on
    } else {
      onClose(); // Close the modal if toggled off
    }
  };
  const handleModalClose = () => {
    onClose(); // Close the modal
    setIsToggled(false); // Set the toggle state to false
  };
  // const [moniDevice, setMoniDevice] = useState(device.deviceId);
  const [videoUrl, setVideoUrl] = useState([]);
  const [noStream, setNoStream] = useState(false);
  useEffect(() => {
    const fetchStreamData = async () => {
      try {
        const response = await axios.get(
          "https://media.arcisai.io:443/rtmp/api/list"
        );
        const streamData = response.data;
        // console.log(response.data)
        // Filter and find the matching path where StreamName matches moniDevice

        const matchedPaths = streamData
          .filter((item) => item.StreamName === `RTSP-${device.deviceId}`) // Match StreamName with moniDevice
          .map((item) => item.Path); // Extract Path

        // console.log(matchedPaths)
        if (matchedPaths.length > 0) {
          setVideoUrl(matchedPaths);
          setNoStream(false); // Stream found
        } else {
          setVideoUrl([]);
          setNoStream(true); // No stream found
        }

        console.log("Matched Paths:", matchedPaths);
      } catch (error) {
        console.error("Error fetching stream data:", error);
        setNoStream(true); // Handle error case as no stream
      }
    };

    fetchStreamData();
  }, [device]);

  const handleBack = () => {
    try {
      // Perform your logic here
      setImageUrl(deviceId); // Set the image URL or any other required action
    } catch (error) {
      console.error("Error in handleBack:", error); // Log the error for debugging
    } finally {
      navigate("/cameras"); // Navigate to the desired URL
    }
  };

  return (
    <>
      <MobileHeader title="Camera View" />

      <Box
        mt={{ base: "12", md: "0" }}
        mb={{ base: "20", md: "5" }}
        p={{ base: 3, md: 0 }}
        maxW="1440px"
        mx="auto"
        color={useColorModeValue("custom.lightModeText", "custom.darkModeText")}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={{ base: 2, md: 3 }}
          borderBottom="1px solid"
          borderColor="gray.200"
          mb={4}
        >
          <Text
            display="flex"
            fontSize={{ base: "sm", md: "md" }}
          // color="gray.600"
          >
            <Box
              as="span"
              onClick={handleBack}
              cursor="pointer"
              _hover={{ color: "blue.500", textDecoration: "underline" }}
            >
              Camera View
            </Box>
            &nbsp;&#62;&nbsp;{deviceId}&nbsp;&nbsp;
            <Tooltip label="Watch AI Stream" aria-label="A tooltip">
              <Switch
                isChecked={isToggled} // The state of the switch
                onChange={handleToggle} // Handle the toggle action
                size="sm"
                colorScheme="teal"
              />
              &nbsp;
              <InfoIcon mt={1} />
            </Tooltip>
          </Text>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            // color="blue.500"
            cursor="pointer"
            _hover={{ color: "blue.500", textDecoration: "underline" }}
            onClick={handleBack} // Update as per your back navigation function
          >
            🔙 Back
          </Text>
        </Flex>

        <Box
          position="relative"
          borderRadius="lg"
          // overflow="hidden"
          mb={4}
          aspectRatio="16/9"
          w="100%"
          boxShadow="lg"
        >
          <Player
            device={device}
            initialPlayUrl={url}
            style={getResponsivePlayerStyle()}
            width="100%"
            height="100%"
            status={status}
            showControls={true}
            className=""
          />
        </Box>
      </Box>

      {isToggled && (
        <Modal isOpen={isOpen} onClose={handleModalClose} size="full">
          <ModalOverlay />
          <ModalContent bg="white">
            <ModalCloseButton
              position="absolute"
              top="10px"
              right="10px"
              zIndex="10"
              color={"Red"}
            />
            <ModalBody>
              <SimpleGrid columns={2} spacing={2}>
                <Player
                  device={device}
                  style={{ width: "48vw", height: "55vh" }}
                  initialPlayUrl={url}
                  showControls={false}
                // ref={playerRef}
                // onPlay={handlePlay}
                // onPause={handlePause}
                // onDestroy={handleDestroy}
                // onFullscreen={handleFullscreen}
                // onScreenshot={handleScreenshot}
                // onAudioToggle={handleAudioToggle}
                // onRecording={handleRecording}
                />

                {videoUrl.map((aiurl, index) => (
                  <>
                    <Player
                      key={index} // Ensure unique key for each player
                      device={device}
                      style={{ width: "48vw", height: "55vh" }}
                      initialPlayUrl={`https://media.arcisai.io:443/hdl/${aiurl}.flv`} // Map each URL to the player
                      showControls={false}
                    // ref={playerRef}
                    // onPlay={handlePlay}
                    // onPause={handlePause}
                    // onDestroy={handleDestroy}
                    // onFullscreen={handleFullscreen}
                    // onScreenshot={handleScreenshot}
                    // onAudioToggle={handleAudioToggle}
                    // onRecording={handleRecording}
                    />
                    {/* <Button onClick={() => handleCloseStream(aiurl)}>Close Stream</Button> */}
                  </>
                ))}
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CameraView;
