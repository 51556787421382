import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const HorizontalBarChart = ({ onlineCamera, offlineCamera }) => {
    const data = {
        labels: ["Online", "Offline"], // Labels for the categories
        datasets: [
            {
                label: "Total number of cameras",
                data: [onlineCamera, offlineCamera], // Values for each category
                backgroundColor: ["#73c04e", "#e53935"], // Green and Red colors
                borderWidth: 1,
                borderRadius: 5, // Rounded corners
                barThickness: 30, // Adjust bar thickness
            },
        ],
    };

    const totalSum = data.datasets[0].data.reduce((acc, val) => acc + val, 0); // Sum of Online and Offline

    const options = {
        indexAxis: "y", // Switches the axes to horizontal
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    pointStyle: "circle", // Circular legend points
                },
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: "Total number of cameras",
                align: "start", // Aligns the title to the left
                font: {
                    size: 14,
                    weight: "bold",
                },
                padding: {
                    top: 0,
                    bottom: 20,
                },
            },
        },
        scales: {
            x: {
                min: 0,
                max: totalSum, // Set max to the sum of the data
                ticks: {
                    stepSize: totalSum / 5, // Optional: Adjust step size for better readability
                },
            },
            y: {
                ticks: {
                    display: false, // Hide labels on y-axis
                },
            },
        },
    };

    return (
        <div style={{ width: "100%", height: "210px" }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default HorizontalBarChart;
