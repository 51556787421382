import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api/ai`;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

export const getDataByDate = async (pageNumber, limit, date, deviceId, modelname) => {
  try {
    const response = await instance.post("/getDataByDate", {
      pageNumber,
      limit,
      date,
      deviceId,
      modelname
    });
    return response.data;
  } catch (error) {
    return { success: false, message: error.response };
  }
};

export const summarizeVideo = async (query_prompt) => {
  try {
    const response = await instance.post("/summarizeVideo", {
      query_prompt,
    });
    return response.data;
  } catch (error) {
    return { success: false, message: error.response };
  }
};

export const getModelNames = async () => {
  try {
    const response = await instance.get("/getModelNames")
    return response.data
  } catch (error) {
    return { success: false, message: error.response };
  }
}

export const getFutureEvents = async () => {
  try {
    const response = await instance.get("/getFutureEvents")
    return response.data
  } catch (error) {
    return { success: false, message: error.response };
  }
}