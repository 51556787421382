import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  Input,
  InputGroup,
  IconButton,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Badge,
  useColorModeValue,
  Image,
  Divider,
  InputLeftElement,
  HStack,
  Tag,
  Portal,
  Tooltip,
  Skeleton,
  SkeletonText,
  InputRightElement,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Heading,
  Select,
  Switch,
  Spacer,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Grid,
  useToast,
  RadioGroup,
  VStack,
  Radio,
  grid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllCameras,
  getSharedCamera,
  getSharedEmails,
  removeSharedCamera,
  removeUserCamera,
  shareCamera,
  updateCamera,
} from "../actions/cameraActions";
import { MdGridView } from "react-icons/md";
import { TfiLayoutListThumb } from "react-icons/tfi";
import { CiCircleRemove, CiMap } from "react-icons/ci";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { LuLayoutList } from "react-icons/lu";

import theme from "../theme";
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from "@chakra-ui/icons";
import {
  getAlertSettings,
  getAreaDetection,
  getAudioInfo,
  getCustomerStats,
  getFace,
  getHumanoid,
  getImageInfo,
  getLineCross,
  getMissingObjectDetection,
  getMotionDetection,
  getQuality,
  getUnattendedObjectDetection,
  getVideoSettings,
  rebootCamera,
  setAlertSettings,
  setAreaDetection,
  setAudioInfo,
  setCustomerStats,
  setFace,
  setHumanoid,
  setImageInfo,
  setLineCross,
  setMissingObjectDetection,
  setMotionDetection,
  setQualitySettings,
  setUnattendedObjectDetection,
  setVideoSettings,
} from "../actions/settingsActions";
import LineCrossCanvas from "../components/Canvas/LineCrossCanvas";
import CustomerCanvas from "../components/Canvas/CustomerCanvas";
import UAOCanvas from "../components/Canvas/UAOCanvas";
import MODCanvas from "../components/Canvas/MODCanvas";
import NoCameraFound from "../components/NoCameraFound";
import AreaCanvas from "../components/Canvas/AreaCanvas";
import MobileHeader from "../components/MobileHeader";

const Cameras = () => {
  const toast = useToast();
  const [isGridView, setGridView] = useState(() => {
    // Load the view preference from localStorage on initialization
    const savedView = localStorage.getItem("cameraView");
    return savedView ? savedView === "grid" : true; // Default to Grid View
  });
  const [tempView, setTempView] = useState(
    isGridView ? "Grid View" : "List View"
  ); // Temporary state for the selection
  const [cameras, setCameras] = useState([]);
  const [sharedCameras, setSharedCameras] = useState([]);
  const [sortStatus, setSortStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(isGridView ? 6 : 20);
  // const tabBg = useColorModeValue("#F1EFFE", "#5F4BB6");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeModal, setActiveModal] = useState(null);
  const [selectedCameraId, setSelectedCameraId] = useState(null);
  const [selectedCameraName, setSelectedCameraName] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedCameraType, setSelectedCameraType] = useState(null);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [shareEmail, setShareEmail] = useState("");
  const [activeTab, setActiveTab] = useState("General");
  const [camerasTab, setCamerasTab] = useState("My Cameras");
  const [timeZoneOffset, setTimeZoneOffset] = useState("+00:00"); // Initial timezone value
  const [brightness, setBrightness] = useState(50);
  const [contrast, setContrast] = useState(50);
  const [saturation, setSaturation] = useState(0);
  const [hue, setHue] = useState(0);
  const [sharpness, setSharpness] = useState(50);
  const [flip, setFlip] = useState(false);
  const [audio, setAudio] = useState(false);
  const [mirror, setMirror] = useState(false);
  const [irCutMode, setIrCutMode] = useState(false);
  const [quality, setQuality] = useState("");
  const [sharedEmails, setSharedEmails] = useState([]);
  // AI Settings States
  const [aiEnabled, setAiEnabled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  // Motion Detection States
  const [motionEnabled, setMotionEnabled] = useState(false);
  const [motionSensitivity, setMotionSensitivity] = useState(0);
  const [motionAudioAlert, setMotionAudioAlert] = useState(false);
  const [motionLightAlert, setMotionLightAlert] = useState(false);
  // Human Detection States
  const [humanEnabled, setHumanEnabled] = useState(false);
  const [humanSensitivity, setHumanSensitivity] = useState(0);
  const [humanSensitivityLevel, setHumanSensitivityLevel] = useState(0);
  const [humanAudioAlert, setHumanAudioAlert] = useState(false);
  const [humanLightAlert, setHumanLightAlert] = useState(false);
  // Face Detection States
  const [faceEnabled, setFaceEnabled] = useState(false);
  const [audioAlert, setAudioAlert] = useState(false);
  const [lightAlert, setLightAlert] = useState(false);
  const [faceSensitivity, setFaceSensitivity] = useState(0);
  // Line Crossing States
  const [lineCrossEnabled, setLineCrossEnabled] = useState(false);
  const [lineCrossAudioAlert, setLineCrossAudioAlert] = useState(false);
  const [lineCrossLightAlert, setLineCrossLightAlert] = useState(false);
  const [lineCrossSensitivity, setLineCrossSensitivity] = useState(0);
  // Line Cross Canvas States
  const [detectLine, setDetectLine] = useState(null);
  const [direction, setDirection] = useState(null);
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  // Area Detection States
  const [areaEnabled, setAreaEnabled] = useState(false);
  const [areaAudioAlert, setAreaAudioAlert] = useState(false);
  const [areaLightAlert, setAreaLightAlert] = useState(false);
  const [areaSensitivity, setAreaSensitivity] = useState(0);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [detectArea, setDetectArea] = useState([]);
  const [areaDirection, setAreaDirection] = useState(null);
  const [Action, setAction] = useState("");
  // Traffic Detection States
  const [trafficEnabled, setTrafficEnabled] = useState(false);
  const [isTrafficModalOpen, setIsTrafficModalOpen] = useState(false);
  const [detectTraffic, setDetectTraffic] = useState(null);
  const [trafficDirection, setTrafficDirection] = useState(null);
  // Unattended Object Detection States
  const [unattendedEnabled, setUnattendedEnabled] = useState(false);
  const [unattendedAudioAlert, setUnattendedAudioAlert] = useState(false);
  const [unattendedLightAlert, setUnattendedLightAlert] = useState(false);
  const [unattendedSensitivity, setUnattendedSensitivity] = useState(0);
  const [isUnattendedModalOpen, setIsUnattendedModalOpen] = useState(false);
  const [detectUnattended, setDetectUnattended] = useState(null);
  const [unattendedDirection, setUnattendedDirection] = useState(null);
  // Missing Object Detection States
  const [missingEnabled, setMissingEnabled] = useState(false);
  const [missingAudioAlert, setMissingAudioAlert] = useState(false);
  const [missingLightAlert, setMissingLightAlert] = useState(false);
  const [missingSensitivity, setMissingSensitivity] = useState(0);
  const [isMissingModalOpen, setIsMissingModalOpen] = useState(false);
  const [detectMissing, setDetectMissing] = useState(null);
  const [missingDirection, setMissingDirection] = useState(null);
  const [unattendedDuration, setUnattendedDuration] = useState(0);
  const [missingDuration, setMissingDuration] = useState(0);

  const handleTimeZoneChange = (event) => {
    setTimeZoneOffset(event.target.value);
  };

  const openModal = (modal, cameraId, cameraName) => {
    setActiveModal(modal);
    setSelectedCameraId(cameraId);
    setSelectedCameraName(cameraName);
    onOpen();
  };

  const openSettingsModal = (modal, deviceId, cameraName, productType) => {
    setActiveModal(modal);
    setSelectedDeviceId(deviceId);
    setSelectedCameraName(cameraName);
    setSelectedCameraType(productType);
    onOpen();
  };

  const openShareModal = (modal, deviceId) => {
    setActiveModal(modal);
    setSelectedDeviceId(deviceId);
    onOpen();
  };

  const openShareAccessModal = (modal, deviceId) => {
    fetchSharedEmails(deviceId);
    setActiveModal(modal);
    setSelectedDeviceId(deviceId);
    onOpen();
  };

  const openRemoveSharedCameraModal = (modal, deviceId) => {
    setActiveModal(modal);
    setSelectedDeviceId(deviceId);
    onOpen();
  };

  const openRemoveCamera = (modal, deviceId) => {
    setActiveModal(modal);
    setSelectedDeviceId(deviceId);
    onOpen();
  };

  const openRemoveAdminShareModal = (modal, deviceId, email) => {
    setActiveModal(modal);
    setSelectedEmailId(email);
    setSelectedDeviceId(deviceId);
    onOpen();
  };

  const closeModal = () => {
    setActiveModal(null);
    setActiveTab("General");
    onClose();
  };

  const handleUpdateCameraName = async (name) => {
    // Update the camera in the cameras array
    try {
      const response = await updateCamera(selectedCameraId, name);
      closeModal();
      setSelectedCameraId(null);
      setSelectedCameraName(null);
      fetchAllCameras();
      console.log("Camera updated:", response);
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };

  const handleShareCamera = async () => {
    // Update the camera in the cameras array
    try {
      const response = await shareCamera(selectedDeviceId, shareEmail);
      closeModal();
      setSelectedDeviceId(null);
      setShareEmail(null);
      fetchAllCameras();
      toast({
        title: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };

  // Sample camera data
  // const cameras = Array(10).fill({
  // name: "Entry Gate No 18",
  // status: "Offline", // "Offline"
  // snapshot: "Snapshot : 15 mins ago",
  // });

  // Colors to match the provided image
  const tabBg = useColorModeValue("#F1EFFE", "#5F4BB6");
  const tabActiveColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );
  const tabInactiveColor = useColorModeValue("#65758B", "custom.tabDarkMode");
  const bgColor = useColorModeValue("custom.primary", "custom.darkModePrimary");
  const textColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const selectedTab = useColorModeValue(
    "custom.primary",
    "custom.darkModePrimary"
  );

  // handle pagination
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
    console.log("Previous Page", page);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
    console.log("Next Page", page);
  };

  const fetchAllCameras = async () => {
    try {
      const response = await getAllCameras(page, itemsPerPage, search);
      console.log("getAllCameras", response);
      setCameras(response.cameras || []);
      setPage(response.page);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    } finally {
      setIsLoading(false); // Stop loading when data is fetched
    }
  };

  const fetchSharedCameras = async () => {
    try {
      const response = await getSharedCamera();
      console.log("getSharedCameras", response);
      setSharedCameras(response.data || []);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    } finally {
      setIsLoading(false); // Stop loading when data is fetched
    }
  };

  const fetchSharedEmails = async (deviceId) => {
    try {
      const response = await getSharedEmails(deviceId);
      setSharedEmails(response.data);
      console.log("getSharedEmails", response);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    } finally {
      setIsLoading(false); // Stop loading when data is fetched
    }
  };

  const email = localStorage.getItem("email");
  const handleRemoveSharedCamera = async () => {
    try {
      // Use emailId if it exists; otherwise, fallback to email
      const emailToUse = selectedEmailId || email;
      console.log("emailToUse", emailToUse);
      const response = await removeSharedCamera(emailToUse, selectedDeviceId);
      console.log("removeSharedCamera", response);

      fetchSharedCameras();
      setSelectedDeviceId(null);
      closeModal();

      toast({
        title: "Camera Access Removed Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  const handleRemoveCamera = async () => {
    try {
      const response = await removeUserCamera(selectedDeviceId);
      console.log("removeUserCamera", response);
      fetchAllCameras();
      closeModal();
      toast({
        title: "Camera Removed Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  }


  // Utility function to calculate the time difference
  const getTimeAgo = (timestamp) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const timeDiffInSeconds = currentTime - timestamp;

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(timeDiffInSeconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  // Fetch initial data
  const fetchCameras = async () => {
    try {
      const response = await getAllCameras(page, itemsPerPage); // Fetch paginated data
      setCameras(response.cameras || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  const updateCameraData = () => {
    setCameras((prevCameras) =>
      prevCameras.map((camera) => {
        const storedData = localStorage.getItem(
          `deviceImage_${camera.deviceId}`
        );
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          return {
            ...camera,
            imageUrl:
              parsedData.imageUrl ||
              "https://delta.arcisai.io/images/CameraCard.png", // Default fallback
            lastOpened: getTimeAgo(parsedData.timestamp),
          };
        }
        return {
          ...camera,
          imageUrl: "https://delta.arcisai.io/images/CameraCard.png", // Default fallback
          lastOpened: "N/A",
        };
      })
    );
  };

  const handleViewChange = (isGrid) => {
    setGridView(isGrid);
    setItemsPerPage(isGrid ? 6 : 20); // Set itemsPerPage to 20 for list view, 6 for grid view
    setPage(isGrid ? 1 : 1)
  };

  useEffect(() => {
    // Save the current view preference to localStorage whenever it changes
    localStorage.setItem("cameraView", isGridView ? "grid" : "list");

    // Fetch cameras and shared cameras
    fetchCameras();
    fetchSharedCameras();

    // Set interval to update camera data from localStorage
    const intervalId = setInterval(updateCameraData, 5000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [isGridView, page]); // Depend on `isGridView` and `page`

  // Sort function based on status
  const sortCameras = (status) => {
    if (status === "online") {
      setCameras((prevCameras) =>
        [...prevCameras].sort((a, b) => (a.status === "online" ? -1 : 1))
      );
    } else if (status === "offline") {
      setCameras((prevCameras) =>
        [...prevCameras].sort((a, b) => (a.status === "offline" ? -1 : 1))
      );
    }
  };

  // Handle click for sorting
  const handleSort = (status) => {
    setSortStatus(status);
    sortCameras(status);
    console.log(status);
  };

  const handleCameraClick = (cameraId, status) => {
    navigate(`/camera/${cameraId}`, { state: { status } });
  };

  ///// Settings API /////

  const fetchData = async () => {
    try {
      if (activeTab === "Media") {
        const response = await getVideoSettings(selectedDeviceId);
        const response2 = await getImageInfo(selectedDeviceId);
        console.log("getVideoSettings", response2);
        setIrCutMode(response2.irCutMode);
        setBrightness(response.brightnessLevel);
        setContrast(response.contrastLevel);
        setSaturation(response.saturationLevel);
        setSharpness(response.sharpnessLevel);
        setHue(response.hueLevel);
        setMirror(response.mirrorEnabled);
        setFlip(response.flipEnabled);
      } else if (activeTab === "General") {
        console.log("getGeneralSettings");
        const qualityResponse = await getQuality(selectedDeviceId);
        console.log("getQuality", qualityResponse);
        console.log('selectedCameraType', selectedCameraType);
        setQuality(qualityResponse.quality.quality);
        if (selectedCameraType === "S-Series") { // Check the camera type
          console.log("S-Series");
          const aiResponse = await getAlertSettings(selectedDeviceId);
          setAiEnabled(aiResponse.bEnable);
          console.log("getAlertSettings", aiResponse);
        }
        const response = await getAudioInfo(selectedDeviceId);
        // console.log("response", response);
        setAudio(response.enabled);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Motion Detection"
      ) {
        const response = await getMotionDetection(selectedDeviceId);
        setMotionEnabled(response.enabled);
        setMotionSensitivity(response.detectionGrid.sensitivityLevel);
        setMotionAudioAlert(response.alarmOut.audioAlert.enabled);
        setMotionLightAlert(response.alarmOut.lightAlert.enabled);
        // console.log("getMotionDetection", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Human Detection"
      ) {
        // console.log("getAISettings");
        const response = await getHumanoid(selectedDeviceId);
        setHumanEnabled(response.Enabled ? response.Enabled : response.enabled);
        setHumanSensitivity(response.Sensitivity);
        setHumanSensitivityLevel(response.sensitivityStep);
        setHumanAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setHumanLightAlert(response.AlarmOut.LightAlert.Enabled);
        // console.log("getHumanDetection", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Face Detection"
      ) {
        const response = await getFace(selectedDeviceId);
        setFaceEnabled(response.Enabled);
        setFaceSensitivity(response.Sensitivity);
        setAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setLightAlert(response.AlarmOut.LightAlert.Enabled);
        // console.log("getFace", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Line Crossing Detection"
      ) {
        const response = await getLineCross(selectedDeviceId);
        setLineCrossEnabled(response.Enabled);
        setLineCrossSensitivity(response.Sensitivity);
        setLineCrossAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setLineCrossLightAlert(response.AlarmOut.LightAlert.Enabled);
        // console.log("getLineCross", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Area Detection"
      ) {
        const response = await getAreaDetection(selectedDeviceId);
        setAreaEnabled(response.Enabled);
        setAreaSensitivity(response.Sensitivity);
        setAreaAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setAreaLightAlert(response.AlarmOut.LightAlert.Enabled);
        setAction(response.Action);
        setAreaDirection(response.Direction);
        // setDetectArea(response.DetectRegion);
        // setAreaDuration(response.MinDuration);
        console.log("getAreaDetection", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Traffic Detection"
      ) {
        const response = await getCustomerStats(selectedDeviceId);
        setTrafficEnabled(response.Enabled);
        // console.log("getCustomerStats", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Unattended Object"
      ) {
        const response = await getUnattendedObjectDetection(selectedDeviceId);
        setUnattendedEnabled(response.Enabled);
        setUnattendedSensitivity(response.Sensitivity);
        setUnattendedAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setUnattendedLightAlert(response.AlarmOut.LightAlert.Enabled);
        setUnattendedDuration(response.Duration);
        // console.log("getunattendedobject", response);
      } else if (
        activeTab === "AI Settings" &&
        activeDropdown === "Missing Object"
      ) {
        const response = await getMissingObjectDetection(selectedDeviceId);
        setMissingEnabled(response.Enabled);
        setMissingSensitivity(response.Sensitivity);
        setMissingAudioAlert(response.AlarmOut.AudioAlert.Enabled);
        setMissingLightAlert(response.AlarmOut.LightAlert.Enabled);
        setMissingDuration(response.Duration);
        // console.log("getMissing", response);
      }
    } catch (error) {
      console.error(`Failed to fetch ${activeTab} settings:`, error);
    }
  };

  useEffect(() => {
    // Only proceed if the modal is open and is the Camera Settings modal
    if (!(isOpen && activeModal === "Camera Settings")) {
      return;
    }

    fetchData();
  }, [isOpen, activeModal, activeTab, activeDropdown]);

  // Set AI Settings

  const handleAISettings = async () => {
    try {
      console.log("handleAISettings", activeDropdown);
      if (activeDropdown === "Motion Detection") {
        const response = await setMotionDetection(
          selectedDeviceId,
          motionEnabled,
          motionSensitivity,
          motionAudioAlert,
          motionLightAlert
        );
        console.log("setMotionDetection", response);
        toast({
          title: "Motion Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Human Detection") {
        const response = await setHumanoid(
          selectedDeviceId,
          humanEnabled,
          humanSensitivity,
          humanSensitivityLevel,
          humanAudioAlert,
          humanLightAlert
        );
        // console.log("setHumanoid", response);
        toast({
          title: "Human Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      else if (activeDropdown === "Face Detection") {
        const response = await setFace(
          selectedDeviceId,
          faceEnabled,
          faceSensitivity,
          audioAlert,
          lightAlert
        );
        console.log("setFace", response);
        toast({
          title: "Face Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Traffic Detection") {
        const response = await setCustomerStats(
          selectedDeviceId,
          trafficEnabled,
          detectTraffic,
          trafficDirection
        );
        console.log("setLineCross", response);
        toast({
          title: "Line Crossing Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Line Crossing Detection") {
        const response = await setLineCross(
          selectedDeviceId,
          lineCrossEnabled,
          lineCrossSensitivity,
          lineCrossAudioAlert,
          lineCrossLightAlert,
          detectLine,
          direction
        );
        console.log("setLineCross", response);
        toast({
          title: "Line Crossing Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Area Detection") {
        const response = await setAreaDetection(
          selectedDeviceId,
          areaEnabled,
          areaSensitivity,
          areaAudioAlert,
          areaLightAlert,
          detectArea,
          areaDirection,
          Action
        );
        console.log("setAreaDetection", response);
        toast({
          title: "Area Detection Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Missing Object") {
        const response = await setMissingObjectDetection(
          selectedDeviceId,
          missingEnabled,
          missingSensitivity,
          missingAudioAlert,
          missingLightAlert,
          detectMissing,
          missingDuration
        );
        console.log("setMissingObjectDetection", response);
        toast({
          title: "Missing Object Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (activeDropdown === "Unattended Object") {
        const response = await setUnattendedObjectDetection(
          selectedDeviceId,
          unattendedEnabled,
          unattendedSensitivity,
          unattendedAudioAlert,
          unattendedLightAlert,
          detectUnattended,
          unattendedDuration
        );
        console.log("setUnattendedObjectDetection", response);
        toast({
          title: "Unattended Object Settings Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };

  // const handleQualityChange = async (value) => {
  //   setQuality(value); // Update the state with the selected quality
  //   const qualityResponse = await setQualitySettings(selectedDeviceId, value);
  //   toast({
  //     title: "Quality Settings Updated Successfully",
  //     status: "success",
  //     duration: 3000,
  //     isClosable: true,
  //   });
  //   fetchData();
  //   console.log("qualityResponse", qualityResponse);
  // };

  // State for quality loader
  const [qualityLoading, setQualityLoading] = useState(false);

  const handleQualityChange = async (value) => {
    setQuality(value); // Update the state with the selected quality
    setQualityLoading(true); // Show the loader
    try {
      const qualityResponse = await setQualitySettings(selectedDeviceId, value);
      toast({
        title: "Quality Settings Updated Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchData(); // Fetch updated data
      console.log("qualityResponse", qualityResponse);
    } catch (error) {
      toast({
        title: "Failed to Update Quality Settings",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error updating quality settings:", error);
    } finally {
      setQualityLoading(false); // Hide the loader
    }
  };

  const handleGeneralSettings = async () => {
    try {
      const response = await setAudioInfo(selectedDeviceId, audio);
      const aiResponse = await setAlertSettings(selectedDeviceId, aiEnabled);
      fetchData();
      setSelectedDeviceId(null);
      closeModal();
      toast({
        title: "Settings Updated Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };

  const handleMediaSettings = async () => {
    try {
      const response = await setImageInfo(selectedDeviceId, irCutMode);
      const response2 = await setVideoSettings(
        selectedDeviceId,
        brightness,
        contrast,
        saturation,
        sharpness,
        hue,
        mirror,
        flip
      );
      console.log("updateVideoSettings", response2);
      fetchData();
      setSelectedDeviceId(null);
      closeModal();
      toast({
        title: "Settings Updated Successfully",
        // description: "The video failed to load.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };

  // Reboot Settings
  const handleRebootCamera = async () => {
    try {
      const response = await rebootCamera(selectedDeviceId);
      console.log("rebootCamera", response);
      setSelectedDeviceId(null);
      closeModal();
      toast({
        title: "Camera Rebooted Successfully",
        // description: "The video failed to load.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating camera:", error);
    }
  };
  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };
  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  // CANVAS MODAL'S

  // line cross modal
  const handleCanvasData = (line, direction) => {
    setDetectLine(line);
    setDirection(direction);
  };
  // traffic detection modal
  const openTrafficModal = () => {
    setIsTrafficModalOpen(true);
  };
  const closeTrafficModal = () => {
    setIsTrafficModalOpen(false);
  };
  const handleTrafficData = (traffic, direction) => {
    setDetectTraffic(traffic);
    setTrafficDirection(direction);
  };
  // unattended object modal
  const openUnattendedModal = () => {
    setIsUnattendedModalOpen(true);
  };
  const closeUnattendedModal = () => {
    setIsUnattendedModalOpen(false);
  };
  const handleUnattendedData = (unattended, direction) => {
    setDetectUnattended(unattended);
    setUnattendedDirection(direction);
  };

  // missing object modal
  const openMissingModal = () => {
    setIsMissingModalOpen(true);
  };
  const closeMissingModal = () => {
    setIsMissingModalOpen(false);
  };
  const handleMissingData = (missing, direction) => {
    setDetectMissing(missing);
    setMissingDirection(direction);
  };

  // Area detection modal
  const openAreaModal = () => {
    setIsAreaModalOpen(true);
  };
  const closeAreaModal = () => {
    setIsAreaModalOpen(false);
  };
  const handleAreaData = (area, direction) => {
    setDetectArea(area);
    setAreaDirection(direction);
  };

  // Canvas gets over here //

  const saveButtonBackgroundColor = useColorModeValue(
    theme.colors.custom.primary,
    theme.colors.custom.darkModePrimary
  );
  const saveButtonColor = useColorModeValue(
    theme.colors.custom.lightModeText,
    theme.colors.custom.darkModeText
  );
  const saveButtonHoverBackgroundColor = useColorModeValue(
    theme.colors.custom.darkModePrimary,
    theme.colors.custom.primary
  );
  const saveButtonHoverColor = useColorModeValue(
    theme.colors.custom.darkModeText,
    theme.colors.custom.lightModeText
  );

  const handleOpenModal = (modalType) => {
    setActiveModal(modalType);
    onOpen();
  };

  const handleApply = () => {
    // Update the main state based on temporary selection
    setGridView(tempView === "Grid View");
    onClose(); // Close the drawer
  };

  return (
    <Box p={3} maxW="1440px" mx="auto" mb={{ base: "20", md: "5" }} >
      {/* Mobile Header */}
      <MobileHeader title="Camera" />


      {/* Tabs for Camera view */}
      <Flex align="center" justifyContent="space-between" w="100%" mt={{ base: "12", md: "0" }}>
        {/* Centered Tabs */}
        <Tabs
          variant="filled"
          bg={useColorModeValue(
            "custom.tabInactiveLightBg",
            "custom.tabInactiveDarkBg"
          )}
          borderRadius="10px"
          boxShadow="1px 1px 10px 0px rgba(0, 0, 0, 0.13) inset"
          mx="auto"
          // minH="35px"
          h={{ base: "auto", md: "36px" }}
          w={{ base: "100%", md: "auto" }} // Full width on mobile
          onChange={(index) =>
            setCamerasTab(["My Cameras", "Shared Cameras"][index])
          }
        >
          <TabList>
            <Tab
              _selected={{
                bg: selectedTab,
                color: tabActiveColor,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
              px={{ base: 0, md: 6 }}
              py={1.5}
              borderRadius="full"
              color={tabInactiveColor}
              h="full" // Ensure full height for consistency
              w={{ base: "50%", md: "auto" }} // Full width on mobile
            >
              My Cameras
            </Tab>
            <Tab
              _selected={{
                bg: selectedTab,
                color: tabActiveColor,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
              px={{ base: 0, md: 6 }}
              py={1.5}
              borderRadius="full"
              color={tabInactiveColor}
              w={{ base: "50%", md: "auto" }} // Full width on mobile
              h="full" // Ensure full height for consistency
            >
              Shared Cameras
            </Tab>
          </TabList>
        </Tabs>

        <InputGroup maxW="300px" display={{ base: "none", md: "flex" }}>
          {/* Hides search input on mobile */}
          <Input
            placeholder="Search camera, Location, Model no."
            // border="1px solid #C7C8CE"
            _focus={{ borderColor: "#C8D6E5" }}
            borderRadius={"10px"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchAllCameras(page, itemsPerPage, search); // Trigger search when pressing Enter
              }
            }}
          />
          <InputRightElement>
            <IconButton
              icon={<IoSearchOutline size="20px" />}
              onClick={(e) => fetchAllCameras(page, 6, search)}
              variant="ghost"
              aria-label="Search"
              _hover={{ bg: "transparent" }} // Remove background on hover
              _focus={{ boxShadow: "none" }} // Remove focus outline
              _active={{ bg: "transparent" }} // Remove background on active state
            />
          </InputRightElement>
        </InputGroup>
        {/* Search Bar aligned to the end */}
      </Flex>

      {/* Filter and Camera Status */}
      <Box w="100%" mt={2}>
        {/* Camera title and Grouping */}
        <HStack width="100%">
          <Text
            fontSize="xl"
            fontWeight="bold"
            display={{ base: "none", md: "flex" }}
          >
            Camera(10)
          </Text>

          {/* <Link color="gray.500" fontSize="sm">
  Grouping
  </Link> */}
        </HStack>

        {/* Filter & View Icons */}
        {camerasTab === "My Cameras" && (
          <>
            <Box mt={4}>
              {" "}
              {/* Added margin-top for spacing */}
              <InputGroup maxW="full" display={{ base: "flex", md: "none" }}>
                <InputLeftElement>
                  <IconButton
                    icon={<IoSearchOutline size="20px" />}
                    onClick={(e) => fetchAllCameras(page, 6, search)}
                    variant="ghost"
                    aria-label="Search"
                    _hover={{ bg: "transparent" }} // Remove background on hover
                    _focus={{ boxShadow: "none" }} // Remove focus outline
                    _active={{ bg: "transparent" }} // Remove background on active state
                  />
                </InputLeftElement>
                <Input
                  placeholder="Search camera, Location, Model no."
                  // border="1px solid #C7C8CE"
                  _focus={{ borderColor: "#C8D6E5" }}
                  borderRadius={"10px"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchAllCameras(page, itemsPerPage, search); // Trigger search when pressing Enter
                    }
                  }}
                />
              </InputGroup>
            </Box>
            <HStack
              mt={2}
              justify="space-between"
              width="100%"
              display={{ base: "flex", md: "flex" }}
            >
              {/* Left Side: Filter and Clear All */}
              <HStack mt={2} justify="space-between" width="100%">
                <Box>
                  <HStack spacing={2}>
                    {" "}
                    {/* Added HStack with spacing for space between tags */}
                    <Tag
                      colorScheme="green"
                      variant="outline"
                      bg={
                        sortStatus === "online"
                          ? " rgba(0,128,0,0.2)"
                          : "rgba(0,0,0,0)"
                      }
                      onClick={() => handleSort("online")}
                      cursor={"pointer"}
                    >
                      <Box
                        w={2}
                        h={2}
                        bg="#95DA25"
                        borderRadius="full"
                        mr={1}
                      />
                      Online
                    </Tag>
                    <Tag
                      colorScheme="red"
                      variant="outline"
                      bg={
                        sortStatus === "offline"
                          ? " rgba(128,0,0,0.2)"
                          : "rgba(0,0,0,0)"
                      }
                      onClick={() => handleSort("offline")}
                      cursor={"pointer"}
                    >
                      <Box
                        w={2}
                        h={2}
                        bg="#FF6262"
                        borderRadius="full"
                        mr={1}
                      />
                      Offline
                    </Tag>
                    <Link
                      fontSize="sm"
                      style={{ textDecoration: "underline", color: "#65758B" }}
                      _hover={{ textDecoration: "none" }}
                      onClick={() => {
                        setSearch("");
                        fetchAllCameras();
                        setSortStatus(null);
                      }}
                    >
                      Clear
                    </Link>
                  </HStack>
                </Box>

                <Flex
                  display={{ base: "flex", md: "none" }} // Shows on small screens, hides on medium+ screens
                  align="center" // Centers items vertically
                  gap={0} // Adds a small gap between the icon and text
                  cursor={"pointer"}
                  onClick={() => handleOpenModal("filterOptions")}
                >
                  <IconButton
                    aria-label="View Option"
                    icon={<Icon as={LuLayoutList} boxSize="16px" />}
                    variant="plain" // No background color for the button
                    size="sm"
                  />
                  <Text fontSize="16px" color="gray.600">
                    View
                  </Text>{" "}
                  {/* Text for the button */}
                </Flex>
              </HStack>

              {/* <HStack>
  <Button
  leftIcon={
  <Image
  src="./images/filter.svg"
  alt="Filter Icon"
  boxSize="20px"
  />
  }
  bg="custom.primary"
  size="sm"
  variant="solid"
  borderRadius="md"
  >
  Filters
  </Button>
  <Link
  fontSize="sm"
  style={{ textDecoration: "underline", color: "#65758B" }}
  _hover={{ textDecoration: "none" }}
  >
  Clear all
  </Link>
  </HStack> */}

              {/* View Options as Tabs */}
              <Tabs
                variant="unstyled"
                display={{ base: "none", md: "flex" }}
                index={isGridView ? 0 : 1} // Sync tabs with isGridView state
                onChange={(index) => handleViewChange(index === 0)} // Update state on tab change
              >
                <TabList
                  width="111px"
                  height="28px"
                  border="1px solid #C7C8CE"
                  borderRadius="10px"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  overflow="hidden"
                  flexShrink={0}
                >
                  <Tooltip
                    label="Grid View"
                    placement="bottom"
                    bg="rgba(0, 0, 0, 0.4)"
                    color="white"
                  >
                    <Tab
                      _selected={{
                        bg: "custom.primary",
                        color: "black",
                      }}
                      borderRight="1px solid #C7C8CE"
                      flex="1"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="0"
                    >
                      <Icon
                        as={MdGridView}
                        boxSize="20px"
                        aria-label="Grid View"
                      />
                    </Tab>
                  </Tooltip>

                  <Tooltip
                    label="List View"
                    placement="bottom"
                    bg="rgba(0, 0, 0, 0.4)"
                    color="white"
                  >
                    <Tab
                      _selected={{
                        bg: "custom.primary",
                        color: "black",
                      }}
                      flex="1"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      padding="0"
                    >
                      <Icon
                        as={TfiLayoutListThumb}
                        boxSize="20px"
                        aria-label="List View"
                      />
                    </Tab>
                  </Tooltip>
                </TabList>
              </Tabs>
            </HStack>
          </>
        )}

        {/* Online and Offline Tags */}
      </Box>

      {camerasTab === "My Cameras" && (
        <>
          {/* Camera Grid */}

          {isLoading ? (
            isGridView ? (
              // Grid View Skeleton Loader
              <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6} mt={4}>
                {[...Array(12)].map((_, index) => (
                  <Box key={index} borderRadius="8px" overflow="hidden">
                    <Skeleton height="242px" borderRadius="8px" />
                    <Box p={2} bg="custom.primary">
                      <SkeletonText noOfLines={2} spacing="4" />
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              // List View Skeleton Loader
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 4 }}
                spacing={6}
                mt={4}
              >
                {[...Array(16)].map((_, index) => (
                  <Flex
                    key={index}
                    borderRadius="8px"
                    overflow="hidden"
                    bg="custom.primary"
                  >
                    <Skeleton
                      width="80px"
                      height="80px"
                      borderRadius="8px"
                      mr={4}
                    />
                    <Box flex="1">
                      <SkeletonText noOfLines={2} spacing="4" />
                    </Box>
                  </Flex>
                ))}
              </SimpleGrid>
            )
          ) : cameras.length > 0 ? (
            isGridView ? (
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                spacing={6}
                mt={4}
                w="100%"
              >
                {cameras.map((camera, index) => {
                  // Get the image URL from localStorage or fall back to default
                  const storedData = localStorage.getItem(
                    `deviceImage_${camera.deviceId}`
                  );
                  const imageUrl = storedData
                    ? JSON.parse(storedData).imageUrl // Parse JSON and extract imageUrl
                    : "https://delta.arcisai.io/images/CameraCard.png"; // Fallback to default
                  const timestamp = storedData
                    ? JSON.parse(storedData).timestamp
                    : null;

                  return (
                    <Box
                      key={index}
                      borderRadius="8px"
                      overflow="hidden"
                      w={"auto"}
                      flexShrink={0}
                    >
                      {/* Image Section */}
                      <Box
                        position="relative"
                        w="100%"
                        onClick={() => handleCameraClick(camera.deviceId, camera.status)}
                      >
                        <Image
                          src={imageUrl} // Use dynamic image URL
                          alt="Camera Snapshot"
                          width="100%"
                          borderRadius="8px 8px 0 0"
                          height={["200px", "242px"]} // Responsive height
                          objectFit="cover"
                        />
                        {/* Status Indicator */}
                        <Box
                          position="absolute"
                          top="2"
                          right="2"
                          bg={
                            camera.status === "online" ? "#95DA25" : "#FF6262"
                          }
                          borderRadius="full"
                          h="13px"
                          w="13px"
                          aria-label="Active status indicator"
                        />

                        {/* Play Icon */}
                        <IconButton
                          aria-label="Play Video"
                          icon={
                            <Image
                              src="./images/playIcon.svg" // Path to your play icon image in public folder
                              alt="Play Icon"
                              boxSize="35px" // Adjust size as needed
                            />
                          }
                          variant="ghost"
                          position="absolute"
                          bottom="2"
                          right="2"
                          isRound
                          size="md"
                        />
                      </Box>

                      {/* Details Section */}
                      <Box p={2} bg={bgColor}>
                        <Flex justify="space-between" align="center">
                          {/* Text Container */}
                          <Box>
                            <Text
                              fontWeight="bold"
                              fontSize="14px"
                              color={textColor}
                            >
                              {camera.name}
                            </Text>

                            <Text fontSize="12px" color={textColor} opacity={0.4}>
                              Last Opened:{" "}
                              {timestamp ? getTimeAgo(timestamp) : "N/A"}
                            </Text>
                          </Box>

                          {/* Menu for More Options */}
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="More options"
                              icon={<BsThreeDotsVertical />}
                              variant="unstyled"
                              size="sm"
                            />
                            <MenuList
                              fontSize="12px" // Decrease font size
                              p={"15px"} // Adjust padding
                            // minWidth="10px" // Set a minimum width for the menu
                            // _hover={{ bg: "purple.100" }}
                            >
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openSettingsModal(
                                    "Camera Settings",
                                    camera.deviceId,
                                    camera.name,
                                    camera.productType
                                  )
                                }
                              >
                                Camera Setting
                              </MenuItem>
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openShareAccessModal("Share Access", camera.deviceId)
                                }
                              >
                                Sharing Access
                              </MenuItem>
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openModal(
                                    "Rename Device",
                                    camera._id,
                                    camera.name
                                  )
                                }
                              >
                                Rename Device
                              </MenuItem>
                              <MenuItem _hover={{ bg: "custom.primary" }}>
                                Pricing Plan
                              </MenuItem>
                              {/* <MenuItem _hover={{ bg: "custom.primary" }}>
                        Manage Cloud Recording
                      </MenuItem>
                      <MenuItem _hover={{ bg: "custom.primary" }}>
                        Camera Details
                      </MenuItem> */}
                              <Divider my={1} w={"90%"} /> {/* Divider added */}
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openShareModal("Share Camera", camera.deviceId)
                                }
                              >
                                Grant Access to Another
                              </MenuItem>
                              {/* <MenuItem _hover={{ bg: "custom.primary" }}>
                        Edit Access Rights to the Camera
                      </MenuItem> */}
                              <Divider my={1} color={"#F2E5FF"} w={"90%"} />{" "}
                              {/* Divider added */}
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                color={"red.500"}
                                onClick={() => {
                                  openRemoveCamera(
                                    "removeUserCamera",
                                    camera.deviceId
                                  );
                                }}
                              >
                                Remove Camera
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                      </Box>
                    </Box>
                  );
                })}
              </SimpleGrid>
            ) : (
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 4 }}
                spacing={6}
                mt={4}
                w="100%"
              >
                {cameras.map((camera, index) => {
                  const storedData = localStorage.getItem(
                    `deviceImage_${camera.deviceId}`
                  );
                  const { imageUrl, timestamp } = storedData
                    ? JSON.parse(storedData)
                    : {
                      imageUrl:
                        "https://delta.arcisai.io/images/CameraCard.png",
                      timestamp: null,
                    };

                  return (
                    <Box
                      key={index}
                      borderRadius="8px"
                      overflow="hidden"
                      bg={bgColor}
                      position="relative" // Make Box position relative for absolute positioning
                    >
                      {/* MenuButton positioned in top-right corner without extra space */}
                      <Box
                        position="absolute"
                        top={1}
                        right={0}
                        zIndex={1}
                        p={0}
                      >
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<BsThreeDotsVertical />}
                            variant="unstyled"
                            size="sm"
                            aria-label="Options"
                          />
                          <Portal>
                            <MenuList
                              fontSize="12px" // Decrease font size
                              p={"15px"} // Adjust padding
                            // minWidth="10px" // Set a minimum width for the menu
                            // _hover={{ bg: "purple.100" }}
                            >
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openSettingsModal(
                                    "Camera Settings",
                                    camera.deviceId,
                                    camera.name,
                                    camera.productType
                                  )
                                }
                              >
                                Camera Setting
                              </MenuItem>
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openShareAccessModal("Share Access", camera.deviceId)
                                }
                              >
                                Sharing Access
                              </MenuItem>
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openModal(
                                    "Rename Device",
                                    camera._id,
                                    camera.name
                                  )
                                }
                              >
                                Rename Device
                              </MenuItem>
                              <MenuItem _hover={{ bg: "custom.primary" }}>
                                Pricing Plan
                              </MenuItem>
                              {/* <MenuItem _hover={{ bg: "custom.primary" }}>
                        Manage Cloud Recording
                      </MenuItem>
                      <MenuItem _hover={{ bg: "custom.primary" }}>
                        Camera Details
                      </MenuItem> */}
                              <Divider my={1} w={"90%"} /> {/* Divider added */}
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                onClick={() =>
                                  openShareModal("Share Camera", camera.deviceId)
                                }
                              >
                                Grant Access to Another
                              </MenuItem>
                              {/* <MenuItem _hover={{ bg: "custom.primary" }}>
                        Edit Access Rights to the Camera
                      </MenuItem> */}
                              <Divider my={1} color={"#F2E5FF"} w={"90%"} />{" "}
                              {/* Divider added */}
                              <MenuItem
                                _hover={{ bg: "custom.primary" }}
                                color={"red.500"}
                                onClick={() => {
                                  openRemoveCamera(
                                    "removeUserCamera",
                                    camera.deviceId
                                  );
                                }}
                              >
                                Remove Camera
                              </MenuItem>
                            </MenuList>
                          </Portal>
                        </Menu>
                      </Box>

                      <Flex align="center" p={0}>
                        <Box
                          position="relative"
                          display="inline-block"
                          onClick={() => handleCameraClick(camera.deviceId, camera.status)}
                          cursor={"pointer"}
                        >
                          <Image
                            src={imageUrl}
                            alt="Camera Snapshot"
                            width="80px"
                            height="80px"
                            borderRadius="8px"
                            objectFit="cover"
                          />

                          {/* Play button */}
                          <IconButton
                            aria-label="Play Video"
                            icon={
                              <Image
                                src="./images/playIcon.svg"
                                alt="Play Icon"
                                boxSize="20px"
                              />
                            }
                            variant="ghost"
                            isRound
                            size="sm"
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                          />

                          {/* Status indicator */}
                          <Box
                            position="absolute"
                            top="1"
                            left="1" // Position indicator at the top-left corner
                            bg={
                              camera.status === "online" ? "#95DA25" : "#FF6262"
                            }
                            borderRadius="full"
                            h="11px"
                            w="11px"
                            aria-label="Active status indicator"
                          />
                        </Box>
                        <Box ml={4}>
                          <Text
                            fontWeight="bold"
                            fontSize="14px"
                            color={textColor}
                            mb={6}
                          >
                            {camera.name}
                          </Text>
                          <Text fontSize="12px" color={textColor} opacity={0.4}>
                            Last Opened:{" "}
                            {timestamp ? getTimeAgo(timestamp) : "N/A"}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </SimpleGrid>
            )
          ) : (
            <NoCameraFound
              title={"Cameras Available"}
              description="It looks like you have not activated any Cameras yet"
            />
          )}
        </>
      )}

      {camerasTab === "Shared Cameras" ? (
        sharedCameras.length > 0 ? (
          <>
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
              spacing={6}
              mt={4}
              w="100%"
            >
              {sharedCameras.map((camera, index) => (
                <Box
                  key={index}
                  // mt={10}
                  // ml={["5", "10"]} // Responsive margin-left for different screen sizes
                  borderRadius="8px"
                  overflow="hidden"
                  // w={["100%", "340px"]} // Responsive width
                  w={"auto"} // Responsive width
                  flexShrink={0}
                // h={"100vh"}
                >
                  {/* Image Section */}
                  <Box
                    position="relative"
                    w="100%"
                    onClick={() => handleCameraClick(camera.deviceId, camera.status)}
                  >
                    <Image
                      src="./images/CameraCard.png" // Replace with actual image source
                      alt="Camera Snapshot"
                      width="100%"
                      borderRadius="8px 8px 0 0"
                      height={["200px", "242px"]} // Responsive height
                      objectFit="cover"
                    />
                    {/* Status Indicator */}
                    <Box
                      position="absolute"
                      top="2"
                      right="2" // Changed 'left' to 'right'
                      bg={camera.status === "online" ? "#95DA25" : "#FF6262"}
                      borderRadius="full"
                      h="13px"
                      w="13px"
                      aria-label="Active status indicator"
                    />

                    {/* Play Icon */}
                    <IconButton
                      aria-label="Play Video"
                      icon={
                        <Image
                          src="./images/playIcon.svg" // Path to your play icon image in public folder
                          alt="Play Icon"
                          boxSize="35px" // Adjust size as needed
                        />
                      }
                      variant="ghost"
                      position="absolute"
                      bottom="2"
                      right="2"
                      isRound
                      size="md"
                    />
                  </Box>

                  {/* Details Section */}
                  <Box p={2} bg={bgColor}>
                    <Flex justify="space-between" align="center">
                      {/* Text Container */}
                      <Box>
                        <Text
                          fontWeight="bold"
                          fontSize="14px"
                          color={textColor}
                        >
                          {camera.name}
                        </Text>
                        <Text fontSize="12px" color="gray.600">
                          {camera.snapshot}
                        </Text>
                      </Box>

                      {/* Menu for More Options */}
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="More options"
                          icon={<BsThreeDotsVertical />}
                          variant="unstyled"
                          size="sm"
                        />
                        <MenuList fontSize="12px" p={"15px"}>
                          {/* <MenuItem _hover={{ bg: "custom.primary" }} onClick={() => openSettingsModal('Camera Settings', camera.deviceId, camera.name)}>
                          Camera Setting
                        </MenuItem>
                        <MenuItem _hover={{ bg: "custom.primary" }} onClick={() => openModal('Rename Device', camera._id, camera.name)}>
                          Rename Device
                        </MenuItem>
                        <MenuItem _hover={{ bg: "custom.primary" }}>
                          Pricing Plan
                        </MenuItem>

                        <Divider my={1} w={"90%"} />
                        <MenuItem _hover={{ bg: "custom.primary" }} onClick={() => openShareModal('Share Camera', camera.deviceId)}>
                          Grant Access to Another
                        </MenuItem>
                        <Divider my={1} color={"#F2E5FF"} w={"90%"} />{" "} */}
                          <MenuItem
                            _hover={{ bg: "custom.primary" }}
                            color={"red.500"}
                            onClick={() => {
                              openRemoveSharedCameraModal(
                                "removeSharedAccess",
                                camera.deviceId
                              );
                            }}
                          >
                            Remove Camera
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </>
        ) : (
          <NoCameraFound
            title="Shared Camera Available"
            description="It looks like you haven't beem granted access to any camera Yet."
          />
        )
      ) : null}

      {/* Pagination */}
      <Flex justify="center" mt={6}>
        {page}/{totalPages}
      </Flex>
      <Flex justify="center" mt={2}>
        <Button
          onClick={handlePreviousPage}
          disabled={page === 1} // Disable if it's the first page
          mr={1}
        >
          Previous
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={page === totalPages} // Disable if it's the last page
          ml={1}
        >
          Next
        </Button>
      </Flex>

      {/* Modal for Sharing Camera */}
      <Modal
        onClose={onClose}
        isOpen={isOpen && activeModal === "Share Camera"}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent
          bg={useColorModeValue("white", theme.colors.custom.darkModeBg)}
          color={textColor}
        >
          <ModalHeader
            textAlign={"center"}
            p={1}
            mt={4}
            color={useColorModeValue(
              theme.colors.custom.lightModeText,
              theme.colors.custom.darkModeText
            )}
          >
            Share Camera
          </ModalHeader>
          <ModalBody pb={6} textAlign="center">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              //   padding="10px"
              p={1}
            >
              <FormControl width="350px" mt={5}>
                <FormLabel
                  htmlFor="device-name"
                  textAlign="start"
                  color={useColorModeValue(
                    theme.colors.custom.lightModeText,
                    theme.colors.custom.darkModeText
                  )}
                >
                  DeviceId:
                </FormLabel>
                <Input
                  id="device-name"
                  placeholder="Device Name"
                  borderColor="gray"
                  borderRadius="10px"
                  px={4}
                  _placeholder={{ color: "gray.400" }}
                  value={selectedDeviceId}
                  disabled
                  _focus={{
                    borderColor: theme.colors.custom.primary, // Custom purple border color on focus
                    boxShadow: `0 0 0 1px ${theme.colors.custom.primary}`, // Custom purple box shadow
                  }}
                />
              </FormControl>

              {/* Email of User */}
              <FormControl width="350px" mt={5}>
                <FormLabel
                  htmlFor="device-name"
                  textAlign="start"
                  color={useColorModeValue(
                    theme.colors.custom.lightModeText,
                    theme.colors.custom.darkModeText
                  )}
                >
                  Email Id:
                </FormLabel>
                <Input
                  id="device-name"
                  placeholder="Enter Email Id"
                  borderColor="gray"
                  borderRadius="10px"
                  px={4}
                  _placeholder={{ color: "gray.400" }}
                  type="email"
                  required
                  onChange={(e) => setShareEmail(e.target.value)}
                  _focus={{
                    borderColor: theme.colors.custom.primary, // Custom purple border color on focus
                    boxShadow: `0 0 0 1px ${theme.colors.custom.primary}`, // Custom purple box shadow
                  }}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter marginRight={"10px"} justifyContent={"space-evenly"}>
            <Button
              onClick={closeModal}
              w="150px"
              border="1px"
              background="0"
              color="red.500"
              borderColor="red.500"
              _hover={{ background: "none" }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => handleShareCamera()}
              w="150px"
              background={useColorModeValue(
                theme.colors.custom.primary,
                theme.colors.custom.darkModePrimary
              )}
              color={useColorModeValue(
                theme.colors.custom.lightModeText,
                theme.colors.custom.darkModeText
              )}
              fontWeight={"normal"}
              _hover={{
                backgroundColor: useColorModeValue(
                  theme.colors.custom.darkModePrimary,
                  theme.colors.custom.primary
                ),
                color: useColorModeValue(
                  theme.colors.custom.darkModeText,
                  theme.colors.custom.lightModeText
                ),
              }}
            >
              Share Camera
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Managing Sharing Access */}
      <Modal
        onClose={onClose}
        isOpen={isOpen && activeModal === "Share Access"}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent
          bg={useColorModeValue("white", theme.colors.custom.darkModeBg)}
          
        >
          <ModalHeader>Sharing Access</ModalHeader>
          <ModalBody pb={6} textAlign="center">
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              //   padding="10px"
              p={1}
            > */}
            {/* <Flex alignItems="center" justifyContent="space-between" mb={4}> */}
            {sharedEmails.map((email, index) => (
              <Flex
                key={index}
                alignItems="center"
                justifyContent="space-between"
                mb={4}
              >
                <Text>{email}</Text>
                <Button
                  color="red.500"
                  variant={'outline'}
                  onClick={() => {
                    openRemoveAdminShareModal(
                      "removeSharedAccess",
                      selectedDeviceId,
                      email
                    );
                  }}
                >
                  Remove
                </Button>
              </Flex>
            ))}
            {/* <Text>Camera model</Text>
              <Text>Remove</Text> */}
            {/* </Flex> */}
            {/* </Box> */}
          </ModalBody>
          <Divider />
          <ModalFooter marginRight={"10px"} justifyContent={"space-evenly"}>
            <Button
              onClick={closeModal}
              w="150px"
              border="1px"
              background="0"
              color="red.500"
              borderColor="red.500"
              _hover={{ background: "none" }}
            >
              Cancel
            </Button>

            {/* <Button
              w="150px"
              background={useColorModeValue(
                theme.colors.custom.primary,
                theme.colors.custom.darkModePrimary
              )}
              color={useColorModeValue(
                theme.colors.custom.lightModeText,
                theme.colors.custom.darkModeText
              )}
              fontWeight={"normal"}
              _hover={{
                backgroundColor: useColorModeValue(
                  theme.colors.custom.darkModePrimary,
                  theme.colors.custom.primary
                ),
                color: useColorModeValue(
                  theme.colors.custom.darkModeText,
                  theme.colors.custom.lightModeText
                ),
              }}
            >
              Save Camera
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Rename Device */}
      <Modal
        onClose={onClose}
        isOpen={isOpen && activeModal === "Rename Device"}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent
          bg={useColorModeValue("white", theme.colors.custom.darkModeBg)}
          color={textColor}
        >
          <ModalHeader
            textAlign={"center"}
            p={1}
            mt={4}
            color={useColorModeValue(
              theme.colors.custom.lightModeText,
              theme.colors.custom.darkModeText
            )}
          >
            Rename Device
          </ModalHeader>
          <ModalBody pb={6} textAlign="center">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              //   padding="10px"
              p={1}
            >
              <FormControl width="350px" mt={5}>
                <FormLabel
                  htmlFor="device-name"
                  textAlign="start"
                  color={useColorModeValue(
                    theme.colors.custom.lightModeText,
                    theme.colors.custom.darkModeText
                  )}
                >
                  Enter Device Name:
                </FormLabel>
                <Input
                  id="device-name"
                  placeholder="Device Name"
                  borderColor="gray"
                  borderRadius="10px"
                  px={4}
                  _placeholder={{ color: "gray.400" }}
                  value={selectedCameraName}
                  onChange={(e) => setSelectedCameraName(e.target.value)}
                  _focus={{
                    borderColor: theme.colors.custom.primary, // Custom purple border color on focus
                    boxShadow: `0 0 0 1px ${theme.colors.custom.primary}`, // Custom purple box shadow
                  }}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter marginRight={"10px"} justifyContent={"space-evenly"}>
            <Button
              onClick={closeModal}
              w="150px"
              border="1px"
              background="0"
              color="red.500"
              borderColor="red.500"
              _hover={{ background: "none" }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => handleUpdateCameraName(selectedCameraName)}
              w="150px"
              background={useColorModeValue(
                theme.colors.custom.primary,
                theme.colors.custom.darkModePrimary
              )}
              color={useColorModeValue(
                theme.colors.custom.lightModeText,
                theme.colors.custom.darkModeText
              )}
              fontWeight={"normal"}
              _hover={{
                backgroundColor: useColorModeValue(
                  theme.colors.custom.darkModePrimary,
                  theme.colors.custom.primary
                ),
                color: useColorModeValue(
                  theme.colors.custom.darkModeText,
                  theme.colors.custom.lightModeText
                ),
              }}
            >
              Save Device
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Camera Settings */}
      <Modal
        onClose={closeModal}
        isOpen={isOpen && activeModal === "Camera Settings"}
        isCentered
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Camera Settings</ModalHeader>

          {/* Modal Body */}
          <ModalBody>
            {/* Tabs */}
            <Tabs
              variant="unstyled"
              mb={6}
              onChange={(index) =>
                setActiveTab(
                  ["General", "Media", "AI Settings", "Network", "System"][
                  index
                  ]
                )
              }
            >
              <TabList>
                <Tab
                  _selected={{
                    fontWeight: "bold",
                    borderBottom: "4px solid",
                    borderColor: useColorModeValue(
                      theme.colors.custom.primary,
                      theme.colors.custom.darkModeText
                    ),
                  }}
                >
                  General
                </Tab>
                <Tab
                  _selected={{
                    fontWeight: "bold",
                    borderBottom: "4px solid",
                    borderColor: useColorModeValue(
                      theme.colors.custom.primary,
                      theme.colors.custom.darkModeText
                    ),
                  }}
                >
                  Media
                </Tab>
                <Tab
                  _selected={{
                    fontWeight: "bold",
                    borderBottom: "4px solid",
                    borderColor: useColorModeValue(
                      theme.colors.custom.primary,
                      theme.colors.custom.darkModeText
                    ),
                  }}
                >
                  AI Settings
                </Tab>
              </TabList>
            </Tabs>

            {/* Conditional Content Based on Active Tab */}
            {activeTab === "General" && (
              <>
                {selectedCameraType === 'S-Series' &&
                  <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Text>AI Notifications</Text>
                    <Switch isChecked={aiEnabled} onChange={() => setAiEnabled(!aiEnabled)} size="md" />
                  </Flex>
                }

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text>Audio</Text>
                  <Switch
                    isChecked={audio}
                    onChange={() => setAudio(!audio)}
                    size="md"
                  />
                </Flex>

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Camera name</Text>
                  <Input
                    disabled
                    defaultValue={selectedCameraName}
                    size="sm"
                    maxW="60%"
                  />
                </Flex>

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Camera model</Text>
                  <Text fontWeight="bold">{selectedCameraType}</Text>
                </Flex>

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Device ID</Text>
                  <Text fontWeight="bold">{selectedDeviceId}</Text>
                </Flex>

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Firmware</Text>
                  <Text fontWeight="bold">V12.98630</Text>
                </Flex>

                {/* <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Quality</Text>
                  <Select
                    value={quality}
                    onChange={(e) => handleQualityChange(e.target.value)}
                    // disabled
                    size="sm"
                    maxW="60%">
                    {
                      selectedCameraType === 'A-Series' &&
                      <option value="low">Low</option>
                    }
                    <option value="mid">Medium</option>
                    <option value="high">High</option>
                  </Select>
                </Flex> */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Quality</Text>
                  {/* <Flex maxW="60%" alignItems="center"> */}
                  <Select
                    value={quality}
                    onChange={(e) => handleQualityChange(e.target.value)}
                    size="sm"
                    maxW="60%"
                  >
                    <option value="low">Low</option>
                    <option value="mid">Medium</option>
                    <option value="high">High</option>
                  </Select>
                  {qualityLoading && <Spinner size="sm" ml={0} />} {/* Loader next to the input */}
                  {/* </Flex> */}
                </Flex>

                {/* <Divider mb={4} />

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Time Zone</Text>
                  <Select
                    value={timeZoneOffset}
                    onChange={handleTimeZoneChange}
                    size="sm"
                    maxW="60%"
                  >
                    {[
                      "-12:00",
                      "-11:00",
                      "-10:00",
                      "-09:00",
                      "-08:00",
                      "-07:00",
                      "-06:00",
                      "-05:00",
                      "-04:00",
                      "-03:00",
                      "-02:00",
                      "-01:00",
                      "+00:00",
                      "+01:00",
                      "+02:00",
                      "+03:00",
                      "+04:00",
                      "+05:00",
                      "+05:30",
                      "+06:00",
                      "+07:00",
                      "+08:00",
                      "+09:00",
                      "+10:00",
                      "+11:00",
                      "+12:00",
                    ].map((tz) => (
                      <option key={tz} value={tz}>{`GMT ${tz}`}</option>
                    ))}
                  </Select>
                </Flex> */}

                <Divider mb={2} />

                <Flex w="full" justifyContent="space-between">
                  {/* <Button colorScheme="red" variant="outline" size="sm">
                Set to Default
              </Button> */}
                  <Button
                    p={0}
                    colorScheme="red"
                    variant="ghost"
                    textDecoration={"underline"}
                    size="sm"
                    onClick={() => handleRebootCamera()}
                  >
                    Reboot Camera
                  </Button>
                  <IconButton
                    colorScheme="red"
                    aria-label="Info"
                    icon={<InfoIcon />}
                    size="sm"
                    variant="ghost"
                  />
                  <Spacer />
                  <Button
                    variant="outline"
                    size="sm"
                    mr={2}
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    size="sm"
                    background={saveButtonBackgroundColor}
                    color={saveButtonColor}
                    fontWeight={"normal"}
                    _hover={{
                      backgroundColor: saveButtonHoverBackgroundColor,
                      color: saveButtonHoverColor,
                    }}
                    onClick={() => handleGeneralSettings()}
                  >
                    Save
                  </Button>
                </Flex>
              </>
            )}

            {/* Media Tab Content */}
            {activeTab === "Media" && (
              <Box>
                {/* <Text mb={4} fontWeight="bold">Media Settings</Text> */}

                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>IR Mode</Text>
                  <Select
                    value={irCutMode}
                    onChange={(e) => setIrCutMode(e.target.value)}
                    size="sm"
                    maxW="60%"
                  >
                    <option value="auto">IrLedMode</option>
                    <option value="light">Light Mode</option>
                    <option value="smart">Smart Mode</option>
                    <option value="daylight">Daylight Mode</option>
                    <option value="night">Night Mode</option>
                  </Select>
                  {/* <IconButton aria-label="Info" icon={<InfoIcon />} size="xs" variant="ghost" /> */}
                </Flex>

                {/* Brightness Slider */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Brightness</Text>
                  <Box flex="1" mx={4}>
                    <Slider
                      value={brightness}
                      onChange={(val) => setBrightness(val)}
                      min={0}
                      max={100}
                      step={1}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Box>
                  <Text>{brightness}%</Text>
                </Flex>

                {/* Contrast Slider */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Contrast</Text>
                  <Box flex="1" mx={4}>
                    <Slider
                      value={contrast}
                      onChange={(val) => setContrast(val)}
                      min={0}
                      max={100}
                      step={1}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Box>
                  <Text>{contrast}%</Text>
                </Flex>

                {/* Saturation Slider */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Saturation</Text>
                  <Box flex="1" mx={4}>
                    <Slider
                      value={saturation}
                      onChange={(val) => setSaturation(val)}
                      min={0}
                      max={100}
                      step={1}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Box>
                  <Text>{saturation}%</Text>
                </Flex>

                {/* Hue Slider */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Hue</Text>
                  <Box flex="1" mx={4}>
                    <Slider
                      value={hue}
                      onChange={(val) => setHue(val)}
                      min={0}
                      max={100}
                      step={1}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Box>
                  <Text>{hue}%</Text>
                </Flex>

                {/* Sharpness Slider */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Sharpness</Text>
                  <Box flex="1" mx={4}>
                    <Slider
                      value={sharpness}
                      onChange={(val) => setSharpness(val)}
                      min={0}
                      max={100}
                      step={1}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Box>
                  <Text>{sharpness}%</Text>
                </Flex>

                {/* Flip Check box */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Flip</Text>
                  <Switch
                    size="sm"
                    isChecked={flip}
                    onChange={() => setFlip(!flip)}
                  />
                </Flex>

                {/* Mirror Check box */}
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text flex="1">Mirror</Text>
                  <Switch
                    size="sm"
                    isChecked={mirror}
                    onChange={() => setMirror(!mirror)}
                  />
                </Flex>

                {/* <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Media Quality</Text>
                  <Input placeholder="Enter media quality" size="sm" maxW="60%" />
                </Flex> */}

                <Divider mb={2} />

                <Flex w="full" justifyContent="space-between">
                  <Button
                    p={0}
                    colorScheme="red"
                    variant="ghost"
                    textDecoration={"underline"}
                    size="sm"
                    onClick={() => handleRebootCamera()}
                  >
                    Reboot Camera
                  </Button>
                  <IconButton
                    colorScheme="red"
                    aria-label="Info"
                    icon={<InfoIcon />}
                    size="sm"
                    variant="ghost"
                  />
                  <Spacer />
                  <Button
                    variant="outline"
                    size="sm"
                    mr={2}
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    size="sm"
                    background={saveButtonBackgroundColor}
                    color={saveButtonColor}
                    fontWeight={"normal"}
                    _hover={{
                      backgroundColor: saveButtonHoverBackgroundColor,
                      color: saveButtonHoverColor,
                    }}
                    onClick={() => handleMediaSettings()}
                  >
                    Save
                  </Button>
                </Flex>
              </Box>
            )}

            {activeTab === "AI Settings" && (
              <Box>
                {/* Dropdown for Motion Detection */}
                <Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    // color={theme.colors.custom.primary}
                    onClick={() =>
                      setActiveDropdown(
                        activeDropdown === "Motion Detection"
                          ? null
                          : "Motion Detection"
                      )
                    }
                    cursor="pointer"
                    mb={4}
                  >
                    <Text>Motion Detection</Text>
                    <Icon
                      as={
                        activeDropdown === "Motion Detection"
                          ? ChevronUpIcon
                          : ChevronDownIcon
                      }
                    />
                  </Flex>
                  {activeDropdown === "Motion Detection" && (
                    <Box pl={4} pb={4}>
                      {/* Grid for Switches */}
                      <Grid
                        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                        gap={4}
                        mb={4}
                      >
                        <Flex
                          width={{ base: "auto", md: "260px" }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>Enable</Text>
                          <Switch
                            isChecked={motionEnabled}
                            onChange={() => setMotionEnabled(!motionEnabled)}
                            size="md"
                          />
                        </Flex>
                        <Flex
                          width={{ base: "auto", md: "260px" }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>Alarm Sound</Text>
                          <Switch
                            isChecked={motionAudioAlert}
                            onChange={() => setMotionAudioAlert(!motionAudioAlert)}
                            size="md" />
                        </Flex>
                        <Flex
                          width={{ base: "auto", md: "260px" }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>Light Alert</Text>
                          <Switch
                            isChecked={motionLightAlert}
                            onChange={() => setMotionLightAlert(!motionLightAlert)}
                            size="md" />
                        </Flex>
                        {/* <Flex width={{ base: 'auto', md: '260px' }} justifyContent="space-between" alignItems="center">
                          <Text>App Notification</Text>
                          <Switch size="md" />
                        </Flex> */}
                      </Grid>

                      {/* Slider for Brightness */}
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mb={4}
                      >
                        <Text flex="1">Sensitivity Level</Text>
                        <Box flex="1" mx={4}>
                          <Slider
                            value={motionSensitivity}
                            onChange={(val) => setMotionSensitivity(val)}
                            min={0}
                            max={100}
                            step={1}
                          >
                            <SliderTrack>
                              <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                          </Slider>
                        </Box>
                        <Text>{motionSensitivity}%</Text>
                      </Flex>
                    </Box>
                  )}
                </Box>

                {/* Dropdown for Human Detection */}
                <Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    // color={theme.colors.custom.primary}
                    onClick={() =>
                      setActiveDropdown(
                        activeDropdown === "Human Detection"
                          ? null
                          : "Human Detection"
                      )
                    }
                    cursor="pointer"
                    mb={4}
                  >
                    <Text>Human Detection</Text>
                    <Icon
                      as={
                        activeDropdown === "Human Detection"
                          ? ChevronUpIcon
                          : ChevronDownIcon
                      }
                    />
                  </Flex>
                  {activeDropdown === "Human Detection" && (
                    <Box pl={4} pb={4}>
                      <Grid
                        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                        gap={4}
                        mb={4}
                      >
                        <Flex
                          width={{ base: "auto", md: "260px" }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>Enable</Text>
                          <Switch
                            isChecked={humanEnabled}
                            onChange={() => setHumanEnabled(!humanEnabled)}
                            size="md"
                          />
                        </Flex>
                        {selectedCameraType === 'S-Series' && (
                          <>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text>Alarm Alert</Text>
                              <Switch
                                isChecked={humanAudioAlert}
                                onChange={() => setHumanAudioAlert(!humanAudioAlert)}
                                size="md" />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={humanLightAlert}
                                onChange={() => setHumanLightAlert(!humanLightAlert)}
                                size="md" />
                            </Flex>
                            {/* <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text>App Notification</Text>
                              <Switch size="md" />
                            </Flex> */}
                            {/* <Flex width={{ base: 'auto', md: '260px' }} justifyContent="space-between" alignItems="center">
                          <Text>RTMP Push</Text>
                          <Switch size="md" />
                        </Flex>
                        <Flex width={{ base: 'auto', md: '260px' }} justifyContent="space-between" alignItems="center">
                          <Text>FTP Push</Text>
                          <Switch size="md" />
                        </Flex> */}
                            {/* <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text>Detection Box</Text>
                              <Switch size="md" />
                            </Flex> */}
                          </>
                        )}
                      </Grid>
                      {selectedCameraType === 'S-Series' ? (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          mb={4}
                        >
                          <Text flex="1">Sensitivity Level</Text>
                          <Box flex="1" mx={4} position="relative">
                            <Slider
                              value={humanSensitivity}
                              onChange={(val) => setHumanSensitivity(val)}
                              min={0}
                              max={10}
                              step={2} // Makes the slider snap to 0, 20, 40, 60, 80, 100
                            >
                              <SliderTrack>
                                <SliderFilledTrack />
                              </SliderTrack>
                              <SliderThumb />
                            </Slider>
                            {/* Custom labels for slider points */}
                            {/* <Flex justifyContent="space-between" mt={2} position="absolute" width="100%">
                            {[0, 20, 40, 60, 80, 100].map((point) => (
                              <Text key={point} fontSize="sm" textAlign="center" width="25px">
                                {point}
                              </Text>
                            ))}
                          </Flex> */}
                          </Box>
                          <Text>{humanSensitivity}</Text>
                        </Flex>
                      ) : (
                        <Flex alignItems="center" justifyContent="space-between" mb={4}>
                          <Text>Sensitivity Level</Text>
                          <Select
                            value={humanSensitivityLevel}
                            onChange={(e) => setHumanSensitivityLevel(e.target.value)}
                            size="sm"
                            maxW="60%"
                          >
                            <option value="lowest">Lowest</option>
                            <option value="low">Low</option>
                            <option value="normal">Normal</option>
                            <option value="high">High</option>
                            <option value="highest">Highest</option>
                          </Select>
                          {/* <IconButton aria-label="Info" icon={<InfoIcon />} size="xs" variant="ghost" /> */}
                        </Flex>
                      )}
                    </Box>
                  )}
                </Box>

                {selectedCameraType === 'S-Series' && (
                  <>
                    {/* Dropdown for Face Detection */}
                    < Box >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Face Detection"
                              ? null
                              : "Face Detection"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Face Detection</Text>
                        <Icon
                          as={
                            activeDropdown === "Face Detection"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Face Detection" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          // gap={4}
                          // mb={4}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Enable Face Detection</Text>
                              <Switch
                                isChecked={faceEnabled}
                                onChange={() => setFaceEnabled(!faceEnabled)}
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Audio Alert</Text>
                              <Switch
                                isChecked={audioAlert}
                                onChange={() => setAudioAlert(!audioAlert)}
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={lightAlert}
                                onChange={() => setLightAlert(!lightAlert)}
                                size="md"
                              />
                            </Flex>
                          </Grid>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Sensitivity Level</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={faceSensitivity}
                                onChange={(val) => setFaceSensitivity(val)}
                                min={0}
                                max={10}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{faceSensitivity}</Text>
                          </Flex>
                          {/* Add additional controls here */}
                        </Box>
                      )}
                    </Box>

                    {/* Dropdown for Line Crossing Detection */}
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Line Crossing Detection"
                              ? null
                              : "Line Crossing Detection"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Line Crossing Detection</Text>
                        <Icon
                          as={
                            activeDropdown === "Line Crossing Detection"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Line Crossing Detection" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          // gap={4}
                          // mb={4}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Line Crossing Detection</Text>
                              <Switch
                                isChecked={lineCrossEnabled}
                                onChange={() =>
                                  setLineCrossEnabled(!lineCrossEnabled)
                                }
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Alarm Alert</Text>
                              <Switch
                                isChecked={lineCrossAudioAlert}
                                onChange={() =>
                                  setLineCrossAudioAlert(!lineCrossAudioAlert)
                                }
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={lineCrossLightAlert}
                                onChange={() =>
                                  setLineCrossLightAlert(!lineCrossLightAlert)
                                }
                                size="md"
                              />
                            </Flex>
                          </Grid>
                          {/* <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Sensitivity Level</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={lineCrossSensitivity}
                                onChange={(val) => setLineCrossSensitivity(val)}
                                min={0}
                                max={100}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{lineCrossSensitivity}</Text>
                          </Flex> */}
                          <Flex
                            width={{ base: "auto", md: "260px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            mb={4}
                          >
                            <Button onClick={openCanvasModal}>Open Canvas</Button>
                          </Flex>
                        </Box>
                      )}
                    </Box>

                    <LineCrossCanvas
                      isOpen={isCanvasModalOpen}
                      onClose={closeCanvasModal}
                      onCanvasData={handleCanvasData}
                      existingCoordinates={detectLine}
                      existingDirection={direction}
                      deviceId={selectedDeviceId}
                    />

                    {/* Dropdown for Traffic/Customer Statistics */}
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Traffic Detection"
                              ? null
                              : "Traffic Detection"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Traffic Detection</Text>
                        <Icon
                          as={
                            activeDropdown === "Traffic Detection"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Traffic Detection" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          // gap={4}
                          // mb={4}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Traffic Detection</Text>
                              <Switch
                                isChecked={trafficEnabled}
                                onChange={() => setTrafficEnabled(!trafficEnabled)}
                                size="md"
                              />
                            </Flex>
                            {/* <Flex width={{ base: 'auto', md: '260px' }} justifyContent="space-between" alignItems="center" mb={4}>
                          <Text>Audio Alert</Text>
                          <Switch isChecked={audioAlert} onChange={() => setAudioAlert(!audioAlert)} size="md" />
                        </Flex> */}
                          </Grid>
                          <Flex
                            width={{ base: "auto", md: "260px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            mb={4}
                          >
                            <Button onClick={openTrafficModal}>Open Canvas</Button>
                          </Flex>
                        </Box>
                      )}
                    </Box>

                    <CustomerCanvas
                      isOpen={isTrafficModalOpen}
                      onClose={closeTrafficModal}
                      onCanvasData={handleTrafficData}
                      existingCoordinates={detectTraffic}
                      existingDirection={trafficDirection}
                      deviceId={selectedDeviceId}
                    />

                    {/* Dropdown for Unattended Luggage Detection */}
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Unattended Object"
                              ? null
                              : "Unattended Object"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Unattended Object</Text>
                        <Icon
                          as={
                            activeDropdown === "Unattended Object"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Unattended Object" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          // gap={4}
                          // mb={4}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Unattended Object Detection</Text>
                              <Switch
                                isChecked={unattendedEnabled}
                                onChange={() =>
                                  setUnattendedEnabled(!unattendedEnabled)
                                }
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Alarm Alert</Text>
                              <Switch
                                isChecked={unattendedAudioAlert}
                                onChange={() =>
                                  setUnattendedAudioAlert(!unattendedAudioAlert)
                                }
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={unattendedLightAlert}
                                onChange={() =>
                                  setUnattendedLightAlert(!unattendedLightAlert)
                                }
                                size="md"
                              />
                            </Flex>
                          </Grid>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Min. Duration</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={unattendedDuration}
                                onChange={(val) => setUnattendedDuration(val)}
                                min={0}
                                max={60}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{unattendedDuration}s</Text>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Sensitivity Level</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={unattendedSensitivity}
                                onChange={(val) => setUnattendedSensitivity(val)}
                                min={0}
                                max={10}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{unattendedSensitivity}</Text>
                          </Flex>
                          <Flex
                            width={{ base: "auto", md: "260px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            mb={4}
                          >
                            <Button onClick={openUnattendedModal}>
                              Open Canvas
                            </Button>
                          </Flex>
                        </Box>
                      )}
                    </Box>

                    <UAOCanvas
                      isOpen={isUnattendedModalOpen}
                      onClose={closeUnattendedModal}
                      onCanvasData={handleUnattendedData}
                      existingCoordinates={detectUnattended}
                      deviceId={selectedDeviceId}
                    />

                    {/* Dropdown for Missing Object */}
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Missing Object"
                              ? null
                              : "Missing Object"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Missing Object</Text>
                        <Icon
                          as={
                            activeDropdown === "Missing Object"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Missing Object" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          // gap={4}
                          // mb={4}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Missing Object Detection</Text>
                              <Switch
                                isChecked={missingEnabled}
                                onChange={() => setMissingEnabled(!missingEnabled)}
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Alarm Alert</Text>
                              <Switch
                                isChecked={missingAudioAlert}
                                onChange={() =>
                                  setMissingAudioAlert(!missingAudioAlert)
                                }
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={missingLightAlert}
                                onChange={() =>
                                  setMissingLightAlert(!missingLightAlert)
                                }
                                size="md"
                              />
                            </Flex>
                          </Grid>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Min. Duration</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={missingDuration}
                                onChange={(val) => setMissingDuration(val)}
                                min={0}
                                max={60}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{missingDuration}s</Text>
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Sensitivity Level</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={missingSensitivity}
                                onChange={(val) => setMissingSensitivity(val)}
                                min={0}
                                max={10}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{missingSensitivity}</Text>
                          </Flex>
                          <Flex
                            width={{ base: "auto", md: "260px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            mb={4}
                          >
                            <Button onClick={openMissingModal}>Open Canvas</Button>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                    <MODCanvas
                      isOpen={isMissingModalOpen}
                      onClose={closeMissingModal}
                      onCanvasData={handleMissingData}
                      existingCoordinates={detectMissing}
                      deviceId={selectedDeviceId}
                    />

                    {/* Dropdown for Area Detection */}
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        // color={theme.colors.custom.primary}
                        onClick={() =>
                          setActiveDropdown(
                            activeDropdown === "Area Detection"
                              ? null
                              : "Area Detection"
                          )
                        }
                        cursor="pointer"
                        mb={4}
                      >
                        <Text>Area Detection</Text>
                        <Icon
                          as={
                            activeDropdown === "Area Detection"
                              ? ChevronUpIcon
                              : ChevronDownIcon
                          }
                        />
                      </Flex>
                      {activeDropdown === "Area Detection" && (
                        <Box pl={4} pb={4}>
                          <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                          >
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Area Detection</Text>
                              <Switch
                                isChecked={areaEnabled}
                                onChange={() => setAreaEnabled(!areaEnabled)}
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Alarm Alert</Text>
                              <Switch
                                isChecked={areaAudioAlert}
                                onChange={() => setAreaAudioAlert(!areaAudioAlert)}
                                size="md"
                              />
                            </Flex>
                            <Flex
                              width={{ base: "auto", md: "260px" }}
                              justifyContent="space-between"
                              alignItems="center"
                              mb={4}
                            >
                              <Text>Light Alert</Text>
                              <Switch
                                isChecked={areaLightAlert}
                                onChange={() => setAreaLightAlert(!areaLightAlert)}
                                size="md"
                              />
                            </Flex>
                          </Grid>
                          {/* <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Text flex="1">Sensitivity Level</Text>
                            <Box flex="1" mx={4}>
                              <Slider
                                value={areaSensitivity}
                                onChange={(val) => setAreaSensitivity(val)}
                                min={0}
                                max={100}
                                step={1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                            <Text>{areaSensitivity}%</Text>
                          </Flex> */}
                          <Flex
                            width={{ base: "auto", md: "260px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            mb={4}
                          >
                            <Button onClick={openAreaModal}>Open Canvas</Button>
                          </Flex>
                        </Box>
                      )}

                    </Box>
                    <AreaCanvas isOpen={isAreaModalOpen} onClose={closeAreaModal} onCanvasData={handleAreaData}
                      existingAction={Action}
                      existingCoordinates={detectArea}
                      existingDirection={areaDirection}
                      deviceId={selectedDeviceId}
                    />

                  </>
                )
                }
                <Divider mb={2} />

                <Flex w="full" justifyContent="space-between">
                  {/* <Button colorScheme="red" variant="outline" size="sm">
Set to Default
</Button> */}
                  <Button
                    p={0}
                    colorScheme="red"
                    variant="ghost"
                    textDecoration={"underline"}
                    size="sm"
                    onClick={() => handleRebootCamera()}
                  >
                    Reboot Camera
                  </Button>
                  <IconButton
                    colorScheme="red"
                    aria-label="Info"
                    icon={<InfoIcon />}
                    size="sm"
                    variant="ghost"
                  />
                  <Spacer />
                  <Button
                    variant="outline"
                    size="sm"
                    mr={2}
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    size="sm"
                    background={saveButtonBackgroundColor}
                    color={saveButtonColor}
                    fontWeight={"normal"}
                    _hover={{
                      backgroundColor: saveButtonHoverBackgroundColor,
                      color: saveButtonHoverColor,
                    }}
                    onClick={() => handleAISettings()}
                  >
                    Save
                  </Button>
                </Flex>
              </Box>
            )}

            {/* Add other tab contents here if needed */}
          </ModalBody>

          {/* Modal Footer */}
          {/* <ModalFooter>
            <Flex w="full" justifyContent="space-between">
              <Button colorScheme="red" variant="ghost" textDecoration={'underline'} size="sm">
                Reboot Camera
              </Button>
              <IconButton colorScheme="red" aria-label="Info" icon={<InfoIcon />} size="sm" variant="ghost" />
              <Spacer />
              <Button variant="outline" size="sm" mr={2} onClick={closeModal}>
                Close
              </Button>
              <Button
                size="sm"
                background={useColorModeValue(
                  theme.colors.custom.primary,
                  theme.colors.custom.darkModePrimary
                )}
                color={useColorModeValue(
                  theme.colors.custom.lightModeText,
                  theme.colors.custom.darkModeText
                )}
                fontWeight={"normal"}
                _hover={{
                  backgroundColor: useColorModeValue(
                    theme.colors.custom.darkModePrimary,
                    theme.colors.custom.primary
                  ),
                  color: useColorModeValue(
                    theme.colors.custom.darkModeText,
                    theme.colors.custom.lightModeText
                  ),
                }}>
                Save
              </Button>
            </Flex>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* removeSharedAccess Modal */}
      <Modal
        isOpen={isOpen && activeModal === "removeSharedAccess"}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          p={3} // Add padding to the modal content
          borderRadius="8px" // Add border radius for rounded corners
          boxShadow="lg" // Add shadow for a floating effect
          // maxW="400px" // Limit width for better responsiveness
          bg={useColorModeValue("white", "gray.800")}
          color={textColor}
        >
          <ModalHeader
            textAlign="center"
            fontSize="xl"
            fontWeight="bold"
            color={useColorModeValue("black", "white")}
          >
            Are you sure?
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" align="center" justify="center">
              <Icon as={CiCircleRemove} color="red.500" boxSize="50px" mb={4} />
              <Text
                textAlign="center"
                color={useColorModeValue("gray.800", "gray.200")}
                fontSize="md"
              >
                Remove access from this camera.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="center" gap={4}>
            <Button
              onClick={onClose}
              w="150px"
              border="1px"
              background="0"
              color="red.500"
              borderColor="red.500"
              _hover={{ background: "none" }}
            >
              Cancel
            </Button>

            <Button
              w={"150px"}
              background={useColorModeValue(
                theme.colors.custom.primary,
                theme.colors.custom.darkModePrimary
              )}
              color={useColorModeValue(
                theme.colors.custom.lightModeText,
                theme.colors.custom.darkModeText
              )}
              fontWeight="normal"
              _hover={{
                backgroundColor: useColorModeValue(
                  theme.colors.custom.darkModePrimary,
                  theme.colors.custom.primary
                ),
                color: useColorModeValue(
                  theme.colors.custom.darkModeText,
                  theme.colors.custom.lightModeText
                ),
              }}
              onClick={handleRemoveSharedCamera}
              borderRadius="6px"
            >
              Remove Access
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* remove user camera Modal */}
      <Modal
        isOpen={isOpen && activeModal === "removeUserCamera"}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          p={3} // Add padding to the modal content
          borderRadius="8px" // Add border radius for rounded corners
          boxShadow="lg" // Add shadow for a floating effect
          // maxW="400px" // Limit width for better responsiveness
          bg={useColorModeValue("white", "gray.800")}
          color={textColor}
        >
          <ModalHeader
            textAlign="center"
            fontSize="xl"
            fontWeight="bold"
            color={useColorModeValue("black", "white")}
          >
            Are you sure?
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" align="center" justify="center">
              <Icon as={CiCircleRemove} color="red.500" boxSize="50px" mb={4} />
              <Text
                textAlign="center"
                color={useColorModeValue("gray.800", "gray.200")}
                fontSize="md"
              >
                Remove access of this camera.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="center" gap={4}>
            <Button
              onClick={onClose}
              w="150px"
              border="1px"
              background="0"
              color="red.500"
              borderColor="red.500"
              _hover={{ background: "none" }}
            >
              Cancel
            </Button>

            <Button
              w={"150px"}
              background={useColorModeValue(
                theme.colors.custom.primary,
                theme.colors.custom.darkModePrimary
              )}
              color={useColorModeValue(
                theme.colors.custom.lightModeText,
                theme.colors.custom.darkModeText
              )}
              fontWeight="normal"
              _hover={{
                backgroundColor: useColorModeValue(
                  theme.colors.custom.darkModePrimary,
                  theme.colors.custom.primary
                ),
                color: useColorModeValue(
                  theme.colors.custom.darkModeText,
                  theme.colors.custom.lightModeText
                ),
              }}
              onClick={handleRemoveCamera}
              borderRadius="6px"
            >
              Remove Camera
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* slide up modal for mobile view */}
      <Drawer
        isOpen={isOpen && activeModal === "filterOptions"}
        placement="bottom"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent borderTopRadius="md" >
          <DrawerHeader textAlign="center" fontSize="lg">
            View By
          </DrawerHeader>
          <DrawerBody>
            <RadioGroup
              onChange={setTempView} // Update temporary state on selection
              value={tempView} // Use temporary state to show the selection
            >
              <VStack align="start" spacing={4}>
                <Radio value="Grid View">Grid View</Radio>
                <Radio value="List View">List View</Radio>
              </VStack>
            </RadioGroup>
          </DrawerBody>
          <DrawerFooter justifyContent="center">
            <Button bg={bgColor} width="50%" onClick={handleApply}>
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Cameras;
