import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Grid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Flex,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-calendar/dist/Calendar.css"; // Import calendar styles
import CustomCard from "../components/CustomCard";
import { getAllCameras } from "../actions/cameraActions";
import { getReports, getTabluarReport } from "../actions/alertActions";
import { IoDownloadOutline } from "react-icons/io5";
import { jsPDF } from "jspdf"; // Import jsPDF for PDF generation
import "jspdf-autotable"; // Ensure this is imported to extend jsPDF
import MobileHeader from "../components/MobileHeader";

const Reports = () => {
  const [selectedDevice, setSelectedDevice] = useState("");
  const [cameras, setCameras] = useState([]);
  const [reports, setReports] = useState({});
  const [tabluarReport, setTabluarReport] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [eventType, setEventType] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal hooks

  const [modalImageUrl, setModalImageUrl] = useState("");
  const [downloadType, setDownloadType] = useState("csv"); // Default to CSV

  const textColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const bgColor = useColorModeValue("custom.primary", "custom.darkModePrimary");

  const handleDeviceChange = (event) => {
    const newDeviceId = event.target.value;
    setSelectedDevice(newDeviceId);
    fetchAllReports(newDeviceId, selectedDate);
    fetchTabularReport(newDeviceId, selectedDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setSelectedDate(formattedDate);
    fetchAllReports(selectedDevice, formattedDate);
    fetchTabularReport(selectedDevice, formattedDate);
  };

  const fetchAllCameras = async () => {
    try {
      const response = await getAllCameras();
      setCameras(response.cameras || []);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  const fetchAllReports = async (deviceId, date) => {
    try {
      setLoading(true);
      const response = await getReports(deviceId, date);
      setReports(response || {});
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTabularReport = async (
    deviceId,
    date,
    page = 1,
    eventType = ""
  ) => {
    try {
      setLoading(true);
      const response = await getTabluarReport(deviceId, date, page, eventType);
      setTabluarReport(response.data || []);
      setTotalPages(response.totalPages || 0);
    } catch (error) {
      console.error("Error fetching tabular reports:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    const newPage = direction === "next" ? currentPage + 1 : currentPage - 1;
    setCurrentPage(newPage);
    fetchTabularReport(selectedDevice, selectedDate, newPage);
  };

  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };

  const handleDownload = () => {
    if (downloadType === "csv") {
      // Generate CSV file
      const data = tabluarReport.map(({ deviceSN, timeStamp, eventType }) => ({
        Device: deviceSN,
        Timestamp: timeStamp,
        EventType: eventType,
      }));
      const csvContent =
        "data:text/csv;charset=utf-8," +
        [
          "Device,Timestamp,EventType",
          ...data.map((d) => Object.values(d).join(",")),
        ].join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tabular_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (downloadType === "pdf") {
      // Generate PDF file
      const doc = new jsPDF();
      const data = tabluarReport.map((item, index) => ({
        SR_No: index + 1, // SR No is the index + 1 (or whatever logic you need for serial number)
        Device: item.deviceSN,
        Timestamp: item.timeStamp,
        EventType: item.eventType,
      }));

      // Add table to PDF
      const tableData = data.map((d) => [d.Device, d.Timestamp, d.EventType]);
      doc.autoTable({
        head: [["SR No", "Device", "Timestamp", "EventType"]], // Add 'SR No' as a header
        body: data.map((d) => [d.SR_No, d.Device, d.Timestamp, d.EventType]), // Include SR No in body
      });

      // Save PDF
      doc.save("tabular_report.pdf");
    }
  };

  // Update handleEventTypeChange function to handle the change
  const handleEventTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedEventType(selectedType);
    if (selectedType === "TOTAL") {
      fetchTabularReport(selectedDevice, selectedDate, currentPage);
    } else {
      fetchTabularReport(
        selectedDevice,
        selectedDate,
        currentPage,
        selectedType
      ); // Include selected eventType in the API call
    }
  };

  useEffect(() => {
    fetchAllCameras();
    fetchTabularReport();
    fetchAllReports();
  }, []);

  const handleOpenModal = (url) => {
    setModalImageUrl(url); // Set the selected URL
    onOpen(); // Open the modal
  };

  return (
    <Box p={4} maxW="1440px" mx="auto" mb={{ base: "20", md: "5" }}>

       {/* Mobile Header */}
       <MobileHeader title="Reports" />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: 4, md: 6 }}
        mt={{ base: "12", md: "0" }}
      >
        <Text
          display={{ base: "none", md: "block" }}
          fontSize={{ base: "lg", md: "2xl" }}
          fontWeight="bold"
          textAlign={{ base: "center", md: "left" }}
        >
          Reports
        </Text>

        <Flex justifyContent={"space-between"} gap={4}>
          <Select
            value={selectedEventType}
            onChange={handleEventTypeChange}
            placeholder="Select Event Type"
          >
            {reports.eventTypeCounts &&
              Object.keys(reports.eventTypeCounts).map((eventType) => (
                <option key={eventType} value={eventType}>
                  {eventType}
                </option>
              ))}
          </Select>

          <Select
            placeholder="Select Device"
            value={selectedDevice}
            onChange={handleDeviceChange}
            // width={{ base: "full", md: "225px" }}
          >
            {cameras.map((camera) => (
              <option key={camera.deviceId} value={camera.deviceId}>
                {camera.deviceId}
              </option>
            ))}
          </Select>

          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="Select Date"
                icon={<CalendarIcon />}
                variant="outline"
                colorScheme="teal"
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  inline
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>

      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(5, 1fr)" }}
        gap={4}
        mt={6}
      >
        {reports.eventTypeCounts &&
          Object.entries(reports.eventTypeCounts).map(([key, value]) => (
            <CustomCard
              key={key}
              value={value}
              title={key}
              color={textColor}
              bcolor="white"
            />
          ))}
      </Grid>

      <Box mt={10}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Tabular Report
          </Text>

          <Flex gap={4}>
            <Select
              value={downloadType}
              onChange={handleDownloadTypeChange}
              width="auto"
            >
              <option value="csv">CSV</option>
              <option value="pdf">PDF</option>
            </Select>

            <IconButton
              aria-label="Download Report"
              icon={<IoDownloadOutline size="20px" />}
              onClick={handleDownload}
            />
          </Flex>
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <Table showColumnBorder={true}>
            <Thead bg={bgColor} textColor={textColor}>
              <Tr>
                <Th>SR No</Th>
                <Th>Image </Th>
                <Th>Device </Th>
                <Th>Event Type</Th>
                <Th>Timestamp</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tabluarReport.length > 0 ? (
                tabluarReport.map((report, index) => (
                  <Tr key={index}>
                    <Td>{(currentPage - 1) * 10 + index + 1}</Td>
                    <Td>
                      <Image
                        src={`https://alert.arcisai.io${report.imageUrl}`}
                        width={"90px"}
                        cursor="pointer" // Makes the image look clickable
                        onClick={() =>
                          handleOpenModal(
                            `https://alert.arcisai.io${report.imageUrl}`
                          )
                        }
                        alt="No Preview Image"
                      />
                    </Td>
                    <Td>{report.deviceSN}</Td>
                    <Td>{report.eventType}</Td>
                    <Td>{report.timeStamp}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="4" textAlign="center">
                    No Data Available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}

        <Box display="flex" justifyContent="center" mt={4} alignItems="center">
          <Flex>
            <IconButton
              aria-label="Previous Page"
              icon={<ChevronLeftIcon />}
              onClick={() => handlePageChange("prev")}
              isDisabled={currentPage === 1}
              // variant="outline"
              mr={2}
            />
            <Text alignSelf="center">
              Page {currentPage} of {totalPages}
            </Text>
            <IconButton
              aria-label="Next Page"
              icon={<ChevronRightIcon />}
              onClick={() => handlePageChange("next")}
              isDisabled={currentPage === totalPages}
              // variant="outline"
              ml={2}
            />
          </Flex>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={modalImageUrl} // Use the image URL from state
              alt="Event Image"
              width="100%" // Full width image inside modal
              objectFit="contain" // Ensures the image scales properly
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Reports;

