import {
    Box,
    Button,
    Checkbox,
    Flex,
    Grid,
    Heading,
    Image,
    Input,
    PinInput,
    PinInputField,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { login } from "../actions/userActions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Otp = () => {

    const [email, setEmail] = useState(''); // Unified field for email or mobile
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState(['', '', '', '', '', '']); // Assuming a 6-digit OTP
    const [isMobileNumber, setIsMobileNumber] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    const bgColor = useColorModeValue(
      "custom.primary",
      "custom.darkModePrimary"
    );

    const textColor = useColorModeValue(
      "custom.lightModeText",
      "custom.darkModeText"
    );

    const handleChange = (value, index) => {
      const newOtp = [...otp];
      newOtp[index] = value; // Set the digit at the corresponding index
      setOtp(newOtp);
    };

    const showToast = (msg, status) => {
      toast({
        description: msg,
        status: status,
        duration: 3000,
        position: "bottom-left",
        isClosable: true,
      });
    };

    const handleLogin = async (e) => {
      e.preventDefault(); // Prevent default form submission

      if (!email || (!password && !isMobileNumber)) {
        setErrorMessage("Please enter all required fields.");
        return;
      }

      try {
        setErrorMessage("");
        setIsLoading(true); // Show loader during login

        const loginResult = await login(email, password);
        console.log("Login result:", loginResult);
        if (loginResult.success) {
          localStorage.setItem("email", loginResult.user.email);
          localStorage.setItem("userId", loginResult.user.customerid);
          navigate("/dashboard");
          showToast("Logged in Successfully", "success");
        } else {
          setErrorMessage(loginResult.message);
          showToast(loginResult.message, "error");
        }
      } catch (error) {
        setErrorMessage("Failed to login. Please try again.");
        console.error("Error:", error);
      } finally {
        setIsLoading(false); // Hide loader
      }
    };

    useEffect(() => {
      const storedEmail = localStorage.getItem("email");
      if (storedEmail) {
        setEmail(storedEmail);
      }
    }, []);

    return (
      <Grid
        h="100vh"
        templateColumns={{ base: "1fr", md: "1fr 1fr" }} // Single column on mobile, two columns on desktop
        bg={useColorModeValue("white", "#231F1F")}
      >
        {/* Image section - only visible on md (tablet) and larger */}
        <Box
          // display={{ base: "none", md: "block" }} // Hide image section on mobile
          // bg="gray.100"
          display={{ base: "none", md: "flex" }}
          // justifyContent={'center'}
          alignItems={"center"}
          h="100%"
        >
          <Image
            src={"./images/sideImage2.png"}
            alt="Login Image"
            objectFit="contain"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h="80vh"
            // w="100%"
          />
        </Box>

        {/* Form section */}
        <Flex
          justify="center"
          align="center"
          bg={useColorModeValue("white", "#231F1F")}
          p={8}
        >
          <Box w="100%" maxW="380px">
            <Box
              mb={1}
              display={{ base: "flex", md: "none" }}
              justifyContent={"center"}
              alignItems={"flex-end"}
            >
              <Image
                src="./images/ArcisAi.png"
                alt="Arcis Logo"
                boxSize={"120px"}
                objectFit={"contain"}
              />
            </Box>
            <Heading
              as="h2"
              size="lg"
              mb={6}
              textAlign={{ base: "center", md: "left" }}
            >
              OTP Login
            </Heading>
            <Text mb={4}>Enter 6 digit OTP share on your {email}</Text>
            {/* Form */}
            <Box as="form">
              {/* <Text>OTP</Text> */}
              <PinInput width="100%">
                {otp.map((digit, index) => (
                  <PinInputField
                    // m={1}
                    key={index}
                    value={digit} // Set the value of the field to the corresponding digit in the OTP array
                    onChange={(value) => handleChange(value, index)} // Handle changes in the OTP field
                    isLast={index === 5} // Only set isLast on the last field
                    mb={3}
                    width="16%" // Set each field to take 16% of the width
                    // mx={1} // Add horizontal margin between fields
                  />
                ))}
              </PinInput>

              <Text textAlign="center" mb={4}>
                Resend OTP in 00:59
                {/* <Link color="purple.500" to='/login'>
                                &nbsp;Login
                            </Link> */}
              </Text>

              <Button
                bg={bgColor}
                color={textColor}
                size="lg"
                w="100%"
                mb={4}
                onClick={handleLogin}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Flex>
      </Grid>
    );
};

export default Otp;
