import React, { useState } from "react";
import {
    Box,
    Grid,
    Flex,
    Text,
    Button,
    Input,
    IconButton,
    useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineSend, AiOutlineMessage, AiOutlineClose } from "react-icons/ai";
import MultipleView from "./MultipleView";
import ArcisGpt from "./ArcisGpt";
import theme from "../theme";

const ChatPanel = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState("");
    const [isRotated, setIsRotated] = useState(false);

    const handleOpen = () => {
        onOpen();
        setIsRotated(true);
    };

    const handleClose = () => {
        onClose();
        setIsRotated(false);
    };

    return (
        <Box w="100%" minHeight="100vh" position="relative">
            {/* Video Feed Section */}
            {/* <Grid templateColumns="repeat(2, 1fr)" gap={4} p={4}> */}
            <MultipleView />
            {/* </Grid> */}

            {/* Floating Chat Button */}
            <IconButton
                position="fixed"
                bottom="20px"
                right="20px"
                // colorScheme={theme.colors.custom.lightModeText}
                bg={theme.colors.custom.primary}
                color={'white'}
                borderRadius="full"
                p={3}
                shadow="lg"
                onClick={isOpen ? handleClose : handleOpen}
                icon={isOpen ? <AiOutlineClose /> : <AiOutlineMessage />}
                aria-label="Chat Button"
            />

            {/* Chat Modal */}
            {isOpen && (
                <Box
                    position="fixed"
                    top="90px"
                    right="0"
                    w="30%"
                    h="80%"
                    bg="white"
                    borderLeftRadius="lg"
                    shadow="lg"
                    p={4}
                    zIndex="10"
                    overflowY="auto"
                >
                    <ArcisGpt />
                </Box>
            )}
        </Box>
    );
};

export default ChatPanel;
