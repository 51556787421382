import {
  Box,
  Select,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Joystick } from "react-joystick-component";

const CameraPTZ = ({ deviceId }) => {
  const [selectedSpeed, setSelectedSpeed] = useState("2");
  const [joystickSize, setJoystickSize] = useState(120); // Default size
  const [topPosition, setTopPosition] = useState("70%"); // Default position
  const [rightPosition, setRightPosition] = useState("7%"); // Default position

  const botBg = useColorModeValue("custom.darkModeBg", "custom.primary");
  const innerBgCOlor = useColorModeValue(
    "custom.darkModePrimary,custom.primary"
  );

  useEffect(() => {
    const updateJoystickPositionAndSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Dynamically calculate joystick size and position based on screen size
      if (screenWidth < 480) {
        // For mobile devices
        setJoystickSize(65);
        setTopPosition("26%"); // Position lower on mobile
        setRightPosition("13%");
      } else if (screenWidth < 768) {
        // For tablets
        setJoystickSize(100);
        setTopPosition("40%"); // Position higher on tablets
        setRightPosition("10%");
      } else {
        // For desktops
        setJoystickSize(120);
        setTopPosition("70%"); // Centered on desktop
        setRightPosition("7%");
      }
    };

    // Call the function on mount and window resize
    updateJoystickPositionAndSize();
    window.addEventListener("resize", updateJoystickPositionAndSize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", updateJoystickPositionAndSize);
    };
  }, []); // Only run this effect on mount and when the screen size changes

  const handleMove = (event) => {
    const direction = event.direction;
    handleJoystickClick(direction);
  };

  const handleJoystickClick = async (direction) => {
    const username = "admin";
    const password = ""; // Ensure you replace this with the actual password
    const moveDuration = 1000; // Duration to move the camera in milliseconds (1 second in this example)

    const step = 0; // Adjust as needed
    const presetNUM = 1; // Adjust as needed

    let act;
    switch (direction) {
      case "LEFT":
        act = "left";
        break;
      case "RIGHT":
        act = "right";
        break;
      case "FORWARD":
        act = "up";
        break;
      case "BACKWARD":
        act = "down";
        break;
      default:
        console.error("Invalid direction");
        return;
    }
    console.log(act);
    const ptzParams = {
      "-step": step,
      "-act": act,
      "-speed": selectedSpeed,
      "-presetNUM": presetNUM,
      deviceId: `${deviceId}.torqueverse.dev`, // Replace with actual deviceId
    };

    const authHeader = "Basic " + btoa(`${username}:${password}`);

    try {
      const response = await axios.post(
        "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz",
        ptzParams,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        }
      );
      console.log("PTZ Status:", response.data);

      await new Promise((resolve) => setTimeout(resolve, moveDuration));

      const stopParams = {
        "-step": step,
        "-act": "stop",
        "-speed": selectedSpeed,
        "-presetNUM": presetNUM,
        deviceId: `${deviceId}.torqueverse.dev`,
      };

      const stopResponse = await axios.post(
        "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz",
        stopParams,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        }
      );
      console.log("Stop PTZ Status:", stopResponse.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        position="absolute"
        top={topPosition}
        right={rightPosition} // Position the component on the right side
        transform="translate(50%, -50%)" // Adjust transform to maintain centering
        zIndex={1}
      >
        <Joystick
          size={joystickSize}
          baseColor="#C8D6E5"
          stickColor="#231F1F"
          move={handleMove}
          stop={() => handleJoystickClick("stop")}
        />
        {/* You can enable the speed selection if needed */}
        {/* <Select
          mt={10}
          justifyContent="space-between"
          ml={0}
          width="110px"
          backgroundColor="#faf6ff"
          color="black"
          border="2px solid rgba(29, 30, 34, 0.7)"
          onChange={(e) => setSelectedSpeed(e.target.value)}
        >
          <option value="1" style={{ color: "black" }}>
            Speed 1
          </option>
          <option value="2" style={{ color: "black" }}>
            Speed 2
          </option>
          <option value="3" style={{ color: "black" }}>
            Speed 3
          </option>
          <option value="4" style={{ color: "black" }}>
            Speed 4
          </option>
          <option value="5" style={{ color: "black" }}>
            Speed 5
          </option>
          <option value="6" style={{ color: "black" }}>
            Speed 6
          </option>
        </Select> */}
      </Box>
    </div>
  );
};

export default CameraPTZ;
