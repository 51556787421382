import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoMdSend } from "react-icons/io";
// import { MdOutlineAttachFile } from "react-icons/md";
import theme from "../theme";
import { summarizeVideo } from "../actions/aiActions";
import axios from "axios";
import MobileHeader from "../components/MobileHeader";

function ArcisGpt() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [typingText, setTypingText] = useState("");

  const chatBoxRef = useRef(null);

  const userBg = useColorModeValue("custom.primary", "custom.darkModeBg");
  const userColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const botBg = useColorModeValue("custom.darkModeBg", "custom.primary");
  const botColor = "black";

  useEffect(() => {
    const fullText = "What can I help you with?";
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index <= fullText.length) {
        setTypingText(fullText.slice(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 40);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const downloadFile = async (filePath) => {
    try {
      const fileUrl = `https://media2.arcisai.io:5080/mp4/${filePath}`;
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Report-${filePath.split("/").pop()}`);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const formatMessageText = (text) => {
    const lines = text.split("\n").map((line, index) => {
      if (line.startsWith("**") && line.endsWith("**")) {
        return (
          <Text key={index} fontWeight="bold" fontSize="md" mt={2} mb={1}>
            {line.replace(/\*\*/g, "")}
          </Text>
        );
      }
      return (
        <Text key={index} fontSize="sm" mb={1}>
          {line}
        </Text>
      );
    });
    return lines;
  };

  const sendMessage = async () => {
    const trimmedInput = input.trim();
    if (!trimmedInput) return;

    const userMessage = { text: trimmedInput, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setInput("");

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: "Typing...", sender: "bot" },
    ]);

    try {
      const response = await summarizeVideo(trimmedInput);
      console.log("Response from summarizeVideo:", response);

      const {
        response: text = "",
        file_path: responseFilePath,
        media: responseMedia = null,
      } = response.data || {};

      const videoUrl = response.data.media ? response.data.media : null;

      const botMessage = {
        text: text || " ",
        sender: "bot",
        filePath: responseFilePath,
        videoUrl: videoUrl,
      };

      setMessages((prevMessages) => [...prevMessages.slice(0, -1), botMessage]);
    } catch (error) {
      console.error("Error in sendMessage:", error.message);

      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: "Something went wrong. Please try again.",
          sender: "bot",
        },
      ]);
    }
  };

  return (
    <Box
      height="80vh"
      maxW="1440px"
      mx="auto"
      pl={6}
      pr={6}
      mt={{ base: "4", md: "0" }}
      display="flex"
      flexDirection="column"
    >
      <MobileHeader title="ArcisGPT" />

      {/* Header Section */}
      <Box mt={{ base: "12", md: "0" }}>
        <Image src="./images/ChatPlusArcis.png" alt="ArcisGPT Logo" mb={4} />
      </Box>

      {/* Chat Section */}
      <Box
        ref={chatBoxRef}
        flex="1"
        overflowY="auto"
        mt={4}
        position="relative"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {messages.length === 0 ? (
          <Flex justify="center" align="center" height="100%">
            <Text
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
              className="wave-text"
            >
              {typingText || " "}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column" gap={4}>
            {messages.map((msg, index) => (
              <Flex
                key={index}
                justify={msg.sender === "user" ? "flex-end" : "flex-start"}
              >
                {msg.sender === "bot" && <Avatar name="ArcisGPT" mr={3} />}
                <Box
                  p={4}
                  borderRadius="md"
                  boxShadow="base"
                  maxW="60%"
                  bg={msg.sender === "user" ? userBg : botBg}
                  color={msg.sender === "user" ? userColor : botColor}
                >
                  {msg.sender === "bot" ? (
                    formatMessageText(msg.text)
                  ) : (
                    <Text fontSize="sm">{msg.text}</Text>
                  )}

                  {msg.filePath && (
                    <Box mt={2}>
                      <Text
                        fontSize="sm"
                        color="blue.500"
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={() => downloadFile(msg.filePath)}
                      >
                        Download PDF Report
                      </Text>
                    </Box>
                  )}

                  {msg.videoUrl && (
                    <Box mt={4}>
                      <video
                        controls
                        width="100%"
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <source
                          src={`https://media2.arcisai.io:5080/mp4/${msg.videoUrl}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </Box>
                  )}
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>

      {/* Input Section */}
      <Box mt={4}>
        <InputGroup size="md">
          {/* <InputLeftElement cursor="pointer">
            <Icon as={MdOutlineAttachFile} boxSize={5} />
          </InputLeftElement> */}
          
          <Input
            placeholder="Ask Me Anything..."
            pl="12"
            pr="12"
            borderRadius="8px"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") sendMessage();
            }}
            _focus={{
              borderColor: theme.colors.custom.primary,
              boxShadow: `0 0 0 1px ${theme.colors.custom.primary}`,
            }}
          />

          <InputRightElement cursor="pointer" onClick={sendMessage}>
            <Icon as={IoMdSend} boxSize={5} />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Box>
  );
}

export default ArcisGpt;
