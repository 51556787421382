import { useState, useEffect } from "react";
import { Box, Flex, VStack, Text, Image, useColorModeValue, Divider } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { TbDeviceCctv } from "react-icons/tb";
import { RiCalendarScheduleLine, RiCheckboxMultipleBlankLine } from "react-icons/ri";
import { IoDocumentTextOutline, IoSettingsOutline } from "react-icons/io5";
import theme from "../theme";
import { SiOpenai } from "react-icons/si";
import { CiCircleInfo, CiDollar } from "react-icons/ci";

function Sidebar({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(
    localStorage.getItem("selectedItem") || "Dashboard"
  );

  // Redirect to /dashboard on first load if no other path is selected
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/login");
      setSelectedItem("Dashboard");
    } else {
      const foundItem = menuItems.find(
        (item) => item.path === location.pathname
      );
      if (foundItem) {
        setSelectedItem(foundItem.label);
      }
    }
  }, [location, navigate]);

  // Update selectedItem in state and localStorage
  const handleItemClick = (label, path) => {
    setSelectedItem(label);
    localStorage.setItem("selectedItem", label); // Save selected item to localStorage
  };

  const textColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const menuItems = [
    {
      label: "Dashboard",
      icon: <MdOutlineSpaceDashboard />,
      path: "/dashboard",
    },
    { label: "Camera", icon: <TbDeviceCctv />, path: "/cameras" },
    {
      label: "Multiscreen",
      icon: <RiCheckboxMultipleBlankLine />,
      path: "/multiple",
    },
    { label: "Events", icon: <RiCalendarScheduleLine />, path: "/events" },
    { label: "Reports", icon: <IoDocumentTextOutline />, path: "/reports" },
    { label: "ArcisGPT", icon: <SiOpenai />, path: "/arcisGpt" },

    { label: "Subscription", icon: <CiDollar />, path: "/subscription" },
    { label: "About Arcis", icon: <CiCircleInfo />, path: "/about" },
    { label: "Settings", icon: <IoSettingsOutline />, path: "/settings" },
    // { label: "Organization Setup", icon: "./images/Organization.svg", path: "/organization-setup" },
    // { label: "Help and Feedback", icon: "./images/feedback.svg", path: "/help-feedback" },
    // { label: "About ArcisAI", icon: "./images/about.svg", path: "/about" },
  ];

  return (
    <Flex>
      <Box
        as="nav"
        boxShadow="1px 2px 20px 1px rgba(0, 0, 0, 0.25)"
        w={
          isSidebarExpanded
            ? ["100%", "150px", "214px"]
            : ["80px", "80px", "80px"]
        }
        transition="width 0.4s ease"
        h="80vh"
        p={isSidebarExpanded ? 3 : 3}
        position="fixed"
        top="100px"
        left="20px"
        zIndex="1000"
        borderRadius="8px"
        bg={useColorModeValue("white", "#231F1F")}
        flexShrink={0}
      >
        <VStack align="start" spacing={6} p={isSidebarExpanded ? 2 : 2}>
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              onClick={() => handleItemClick(item.label, item.path)}
              style={{ width: "100%" }}
            >
              <Flex
                cursor="pointer"
                align="center"
                w="full"
                bg={
                  selectedItem === item.label ? "custom.primary" : "transparent"
                }
                p={1.5}
                borderRadius="8px"
                _hover={{
                  // Add background color on hover if needed
                  "& > *": {
                    color: textColor, // Set hover color to white for both icon and text
                    fontWeight: "700", // Optional: Change font weight on hover for both icon and text
                  },
                }}
              >
                <Box
                  color={
                    selectedItem === item.label
                      ? "custom.selectedBottomNavText"
                      : "custom.bottomNavText"
                  }
                  fontSize="23px"
                  filter={
                    selectedItem === item.label
                      ? "saturate(1%) brightness(20%)"
                      : "none"
                  }
                  p={0}
                >
                  {item.icon}
                </Box>

                {isSidebarExpanded && (
                  <Text
                    ml={4}
                    sx={{
                      color:
                        selectedItem === item.label
                          ? "custom.selectedBottomNavText"
                          : "custom.bottomNavText",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: selectedItem === item.label ? "700" : "400",
                      lineHeight: "normal",
                      letterSpacing: "-0.28px",
                    }}
                  >
                    {item.label}
                  </Text>
                )}
              </Flex>
            </Link>
          ))}
          <Divider />
          <Flex>
            {isSidebarExpanded && (
              <Text
                ml={4}
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "normal",
                  letterSpacing: "-0.28px",
                }}
              >
                v0.9.21
              </Text>
            )}
          </Flex>
        </VStack>
      </Box>
    </Flex>
  );
}

export default Sidebar;
